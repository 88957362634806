import React, { Component, Fragment } from 'react'
import { ReactComponent as PodcastWave } from '../assets/svg/podcast-wave.svg';
import Parse from 'html-react-parser';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { ReactComponent as FilterCloseIcon } from '../assets/svg/filter-close.svg';

export default class GalleryModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      albumHtml: ''
    }

    {this.props.images !== false && this.props.images.map((image, i) => {
      var total = this.props.images.length
      var middle = Math.round(total/2)

      if (i === 0) {
        this.state.albumHtml += '<div class="one-of-two"><img src="'+image.sizes.medium+'" alt="" />'
      } else if (i === middle) {
        this.state.albumHtml += '</div><div class="one-of-two"><img src="'+image.sizes.medium+'" alt="" />'
      } else if (i === total) {
        this.state.albumHtml += '<img src="'+image.sizes.medium+'" alt="" /></div>'
      } else {
        this.state.albumHtml += '<img src="'+image.sizes.medium+'" alt="" />'
      }
    })}
  }

  render() {
    const options = {
      buttons: {
        backgroundColor: "#ffffff00",
        showAutoplayButton: false,
        showDownloadButton: false,
        showThumbnailsButton: false,
      },
      settings: {
        lightboxTransitionSpeed: 0.1,
        slideTransitionSpeed: 0.1,
      }
    };

    return (
      <section className="single-post pop-up publish-block gallery-single">
      	<a href="/" onClick={this.props.closeModal} className="post-exit-button"><FilterCloseIcon/></a>
      	<div className="background-dark category-top">
      		<div className="top-content">
      			<h1 className="color-white">{this.props.title}</h1>
      			<p className="color-white"></p>
      			<div className="subtitle color-white"><span className="color-white">{this.props.date}</span></div>
      		</div>
      		<PodcastWave />
      	</div>

      	<div className="margin-top images-container">
            <SRLWrapper options={options}>
              {Parse(this.state.albumHtml)}
            </SRLWrapper>
      	</div>
      </section>
    )
  }

}
