import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import { ReactComponent as ButtonIcon } from '../assets/svg/button-icon.svg';
import { Link } from 'react-router-dom';
import Parse from 'html-react-parser';
import { ReactComponent as MessagesIcon } from '../assets/svg/messeges-icon.svg';
import { ReactComponent as BravoIcon } from '../assets/svg/bravo-icon.svg';
import { ReactComponent as CommentIcon } from '../assets/svg/comment.svg';
import { ReactComponent as PlayBackground } from '../assets/svg/playpause.svg';
import { ReactComponent as Play } from '../assets/svg/play-icon.svg';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import DebatModal from '../components/DebatModal'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import Modal from 'react-modal';
import TimeAgo from 'javascript-time-ago'
import Moment from 'moment';
import VisibilitySensor from 'react-visibility-sensor';

export default class Debat extends React.Component {

  constructor(props) {
    super(props);
    if (!this.props.user || this.props.loggedIn.length < 1) {
      this.props.history.push('/')
    }
    this.state = {
      posts: [],
      setIsOpen: false,
      isLoaded: false,
      showingMore: false,
      shareOpen: false,
      likedThisPost: false,
      userLikedDebates: false,
      modalPost: false,
      customStyles: {
        content : {
          height                : '100%',
          width                 : '100%',
          padding               : 0,
          border                : 'none',
          'borderRadius'       : '0',
          position: 'relative',
          transform: 'none',
          inset: 'unset',
          bottom: 0,
          'overflowY': 'scroll',
          'marginRight': 0,
          top: 0,
          left: 0,
        },
        overlay: {
          zIndex                : '9',
          border                : 'none',
          marginTop             : '32px',

        },
      },
      getDebatesAgain: false,
      customLabels: {
        second: {
          past: {
            one: "{0} s siden",
            other: "{0} s siden"
          },
        },
        minute: {
          past: {
            one: "{0} min siden",
            other: "{0} min siden"
          },
        },
        hour: {
          past: {
            one: "{0} t siden",
            other: "{0} t siden"
          },
        },
        day: {
          past: {
            one: "{0} dag siden",
            other: "{0} dag siden"
          },
        },
      },
      round: [
          {
            // "second" labels are used for formatting the output.
            formatAs: 'second'
          },
          {
            // This step is effective starting from 59.5 seconds.
            minTime: 60,
            // "minute" labels are used for formatting the output.
            formatAs: 'minute'
          },
          {
            // This step is effective starting from 59.5 minutes.
            minTime: 60 * 60,
            // "hour" labels are used for formatting the output.
            formatAs: 'hour'
          },
          {
            // This step is effective starting from 59.5 minutes.
            minTime: 60 * 60 * 24,
            // "hour" labels are used for formatting the output.
            formatAs: 'day'
          },
      ],
      visiblePosts: [],
      visiblePostsTop: [],
      playingVideos: []
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);
    this.openSharePost = this.openSharePost.bind(this);
    this.handleSharePost = this.handleSharePost.bind(this);
    this.handleLikePost = this.handleLikePost.bind(this);
    this.handleCommentPost = this.handleCommentPost.bind(this);
    this.playVid = this.playVid.bind(this);
  }

  openModal(e) {
    e.preventDefault()
    var postKey = e.currentTarget.dataset.postkey

    this.setState({
      modalPost: this.state.posts[postKey],
      setIsOpen:true
    })
  }

  closeModal(e){
    e.preventDefault()
    this.setState({setIsOpen:false})
  }

  playVid(e) {
    var postKey = e

    if (this.state.playingVideos.includes(postKey) === false) {
      var currentlyPlayingVids = this.state.playingVideos
      var videoObj = document.querySelector('.video-'+postKey)
      currentlyPlayingVids.push(postKey)
      this.setState({
        playingVideos: currentlyPlayingVids,
      })

      videoObj.play()
      videoObj.setAttribute("controls","controls")
    } else {
      // var currentlyPlayingVids = this.state.playingVideos
      // var index = this.state.playingVideos.indexOf(slug)
      // currentlyPlayingVids.splice(index, 1);
      // this.setState({
      //   playingVideos: currentlyPlayingVids
      // })
    }

  }

  openSharePost(e) {
    this.setState({
      shareOpen: !this.state.shareOpen,
    })
  }

  handleLikePost(e) {
    if (this.props.loggedIn.length > 0) {
      const postId = e.currentTarget.dataset.post
      const userId = this.props.user.id
      const _this = this;
      const dataArray = new FormData();
      dataArray.append("postId", postId);
      dataArray.append("userId", userId);

      axios.post("/wp-json/wp/v2/debate-like", dataArray, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(
        function(res) {
          _this.setState({
            postLikes: res.data,
            likedPost: !_this.state.likedPost,
            getDebatesAgain: true
          })
          document.querySelector('button.bravo').classList.toggle('active')
        }
      ).catch(err => console.log(err));
    }
  }

  handleSharePost(e) {
    if (this.props.loggedIn.length > 0) {
      const postId = e.currentTarget.dataset.post
      const userId = this.props.user.id
      const _this = this;
      const dataArray = new FormData();
      dataArray.append("postId", postId);
      dataArray.append("userId", userId);

      axios.post("/wp-json/wp/v2/debate-share", dataArray, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(
        function(res) {
          _this.setState({
            postShares: res.data,
            getDebatesAgain: true
          })
        }
      ).catch(err => console.log(err));
      this.getDebates()
    }
  }

  handleCommentPost(e) {
    if (this.props.loggedIn.length > 0) {
      const postId = e.currentTarget.dataset.post
      const userId = this.props.user.id
      const _this = this;
      const dataArray = new FormData();
      dataArray.append("postId", postId);
      dataArray.append("userId", userId);

      axios.post("/wp-json/wp/v2/debate-comment", dataArray, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(
        function(res) {
          _this.setState({
            postShares: res.data,
          })
        }
      ).catch(err => console.log(err));
    }
  }

  handleShowMore(e) {
    e.preventDefault()
    this.setState({
      showingMore: !this.state.showingMore,
    })
    this.getDebates()
  }

  getDebates() {
    wp.debateResource = wp.registerRoute( 'wp/v2', 'get-debates' );
    var _this = this
    wp.debateResource().get()
    .then(
      function(res) {
        _this.setState({
          title: res.pageTitle,
          subtitle: res.pageSubtitle,
          buttonText: res.buttonText,
          buttonLink: res.buttonLink,
          text: res.text,
          topColor: res.topColor,
          posts: res.posts,
          isLoaded: true
        })
      }
    )
    .catch(err => console.log(err));
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    this.getDebates()
  }

  componentDidUpdate() {
    if (this.props.user && this.props.user.acf && this.state.userLikedDebates === false) {
      try {
        this.setState({userLikedDebates: JSON.parse(this.props.user.acf.liked_posts_debate)})
      } catch (e) {
        this.setState({userLikedDebates: 0})
      }
    }
    if (this.state.getDebatesAgain) {
      this.getDebates()
      this.setState({getDebatesAgain:false})
    }
    if (this.props.loggedIn.length < 1) {
      this.props.history.push('/')
    }
  }

  onChange(visible, id) {
    if (visible) {
      if (this.state.visiblePosts.includes(id) === false) {
        var currentlyVisiblePosts = this.state.visiblePosts
        currentlyVisiblePosts.push(id)
        this.setState({
          visiblePosts: currentlyVisiblePosts
        })
      }
    }
  }
  onChange2(visible, id) {
    if (visible) {
      if (this.state.visiblePostsTop.includes(id) === false) {
        var currentlyVisiblePostsTop = this.state.visiblePostsTop
        currentlyVisiblePostsTop.push(id)
        this.setState({
          visiblePostsTop: currentlyVisiblePostsTop
        })
      }
    }
  }

  render() {
    const {posts, isLoaded, setIsOpen, showingMore, shareOpen, userLikedDebates, customStyles, modalPost, topColor, playingVideos} = this.state;
    setTimeout(function(){ document.body.classList.remove('loading'); },500);

    if (isLoaded && this.props.user) {
      TimeAgo.addLabels('da', 'custom', this.state.customLabels)
      const timeAgo = new TimeAgo('da-DK')
      const customStyle = {
        steps: this.state.round,
        labels: 'custom'
      }

      return (
        <>
          {Parse('<style>.debate-post .features-buttons button.active svg path,body .post-content ul.features-list li svg path,body .post-content ul.features-list li span,body .members-list-container ul li .right-side button svg path,.debat-posts .post-image button > svg path{fill:'+topColor+'}.features-list *{color:'+topColor+'!important}</style>')}
          <div style={{backgroundColor:topColor}} className="category-top">
          	<div className="top-content samling-result color-white">
          		<h1 className="color-white">{this.state.title}</h1>
          		<div className="subtitle">{this.state.subtitle}<span><span className="border">|</span><span>{this.state.posts.length}</span> indlæg</span></div>
              <Link
                className="background-white color-dark button with-icon"
                to={{
                  pathname: this.state.buttonLink,
                }}
              >
                  <ButtonIcon />{this.state.buttonText}
              </Link>
              {Parse(this.state.text)}
          	</div>
          	<SearchResultsWave />
          </div>
          <section className="post-list debat-posts">
            {posts.length > 0 && posts.map((post, i) => {
              return (
                <VisibilitySensor key={i} onChange={isVisible => this.onChange(isVisible, post.id)} >
                  <>
                    <div key={i} className={`post-block ${this.state.visiblePosts.includes(post.id) ? 'is-visible' : ''} ${this.state.visiblePostsTop.includes(post.id) ? 'top-is-visible' : ''}`}>
                      <div className="author-image">
                        {post.authorPic && post.authorPic.sizes &&
                          <img src={post.authorPic.sizes.thumbnail} />
                        }
                      </div>
                      <div className="post-content debate-post background-cacoa">

                        <SwitchTransition mode={'out-in'}>
                          <CSSTransition
                            key={shareOpen}
                            addEndListener={(node, done) => {
                              node.addEventListener("transitionend", done, false);
                            }}
                            classNames="content"
                          >
                            <>
                              {shareOpen &&
                                <div className={'share-container '+(this.shareOpen ? 'active' : '')}>
                                  <div className={'share-buttons'}>
                                    <EmailShareButton
                                      url={window.location.href}
                                      subject={this.state.title}
                                      body={window.location.href}
                                      data-post={post.id}
                                     >
                                      <EmailIcon onClick={this.handleSharePost} EmailShareButtonsize={32} round={true} />
                                    </EmailShareButton>
                                    <FacebookShareButton
                                      url={window.location.href}
                                      quote={this.state.title}
                                      onClick={this.handleSharePost}
                                      data-post={post.id}
                                    >
                                      <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <LinkedinShareButton
                                      url={window.location.href}
                                      onClick={this.handleSharePost}
                                      data-post={post.id}
                                    >
                                      <LinkedinIcon  size={32} round />
                                    </LinkedinShareButton>
                                    <TwitterShareButton
                                      url={window.location.href}
                                      title={this.state.title}
                                      onClick={this.handleSharePost}
                                      data-post={post.id}
                                      className="Demo__some-network__share-button"
                                    >
                                      <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                  </div>
                                </div>
                              }
                            </>
                          </CSSTransition>
                        </SwitchTransition>
                        <div className="features-buttons">
                          <button onClick={this.openSharePost} className="messeges">
                            <MessagesIcon />
                          </button>
                          <button data-post={post.id} onClick={this.handleLikePost} className={`bravo ${Object.values(userLikedDebates).indexOf(post.id.toString()) > -1 ? "active" : ""}`}>
                            <BravoIcon />
                          </button>
                          <button onClick={this.openModal} data-postkey={i} className="stars">
                            <CommentIcon />
                          </button>
                        </div>
                        <VisibilitySensor key={i} onChange={isVisible => this.onChange2(isVisible, post.id)} >
                          <div className="post-data color-dark">
                            <span className="upload-date"><DateFormatted string={timeAgo.format(Date.parse(post.date.replace(/-/g, '/').replace(/[a-z]+/gi, ' ')), customStyle)} date={post.date} /></span>
                            <span className="border">|</span>
                            <span className="post-author">{post.authorName}</span>
                          </div>
                        </VisibilitySensor >
                        <h3 onClick={this.openModal} data-postkey={i} className="color-dark">{post.title}</h3>
                        {post.file && post.file.type == 'image' &&
                          <div className="post-image">
                            <img src={post.file.sizes.medium} />
                          </div>
                        }
                        {post.file && post.file.type == 'video' &&
                          <div className="post-image">
                            <div className="">
                              <video className={'video-'+post.id}>
                                <source src={post.file.url} type={post.file.mime_type}/>
                                Your browser does not support the video tag.
                              </video>
                            </div>
                            <button className={`${this.state.playingVideos.includes(post.id) ? 'hidden' : ''}`} onClick={() => this.playVid(post.id)} data-postkey={post.id}>
                              <PlayBackground/>
                              <span className="play-active"><Play /></span>
                            </button>
                          </div>
                        }
                        <div className="post-short-text color-dark">
                          {post.content.length >= 300 &&
                            <>
                              <a href="/" onClick={this.handleShowMore} className="showmore-container color-dark">
                                {Parse(post.content.split('<!--more-->')[0])}
                                <CSSTransition
                                  in={!showingMore}
                                  timeout={0}
                                  classNames="content"
                                  unmountOnExit
                                >
                                  <span className="read-more-link color-dark"> Læs mere</span>
                                </CSSTransition>
                              </a>
                              <CSSTransition
                                in={showingMore}
                                timeout={100}
                                classNames="content"
                                unmountOnExit
                              >
                                {Parse('<span class="show-more"><p>'+post.content.split('<!--more-->')[1]+'</span>')}
                              </CSSTransition>
                            </>
                          }
                          {post.content.length < 300 &&
                            <>
                              {Parse(post.content)}
                            </>
                          }
                        </div>
                        <ul onClick={this.openModal} data-postkey={i} className="features-list debat">
                          <li className="messeges">
                            <MessagesIcon />
                            <span>{post.shares && post.shares.length > 0 ? post.shares.length : 0}</span>
                          </li>
                          <li className="bravo">
                            <BravoIcon />
                            <span>{post.likes && post.likes.length > 0 ? post.likes.length : 0}</span>
                          </li>
                          <li className="stars">
                            <CommentIcon />
                            <span>{post.comments ? post.comments : 0}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                </VisibilitySensor>
              )
            })}
            {setIsOpen &&
              Parse('<style>.ReactModal__Overlay:after{content:"";top: 1px;width:calc(100% - 36px);height: 27px;position: absolute;border-left: 18px solid white;border-right: 18px solid white;border-top: 7px solid white;transform: translateY(-100%)}</style>')
            }
            <Modal
              isOpen={setIsOpen}
              onRequestClose={this.closeModal}
              style={customStyles}
              closeTimeoutMS={200}
            >
              <DebatModal
                closeModal={this.closeModal}
                post={modalPost}
                user={this.props.user}
                token={this.props.loggedIn}
              />
            </Modal>

          </section>
        </>
      );
    }
    return ''
  }
}

function DateFormatted(props) {
  if (props.string.includes('dag siden') && props.string !== '1 dag siden') {
    return (
      Moment(props.date).format('DD.MM.YYYY')
    )
  }
  return (
    props.string
  )
}
