import React, { Component, Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, useHistory, Link } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LatestPosts from './pages/LatestPosts';
import Homepage from './pages/Homepage';
import PostPage from './pages/PostPage';
import SerieOverview from './pages/SerieOverview';
import Resultater from './pages/Resultater';
import CategoryPage from './pages/CategoryPage';
import TopicPage from './pages/TopicPage';
import TagPage from './pages/TagPage';
import UserPage from './pages/UserPage';
import SavedContent from './pages/SavedContent';
import Billedgalleri from './pages/Billedgalleri';
import Debat from './pages/Debat';
import Page from './pages/Page';
import MostVisited from './pages/MostVisited';
import LogoButton from './components/LogoButton';
import MenuLogIn from './components/Menu';
import MenuLoggedIn from './components/Menu';
import Menu from './components/Menu';
import Filter from './components/Filter';
import './styles/header.css'
import './styles/global.css'
import axios from 'axios';
import "./App.scss"
import { wp } from './index.js'
import { createSlice, configureStore } from '@reduxjs/toolkit'
import Parse from 'html-react-parser';
import PageTransition from 'react-router-page-transition';
// import ReactGA from 'react-ga';

export const App = (props) =>{

  const [allTopics, setAllTopics] = useState(false);
  const [allCats, setAllCats] = useState(false);
  const [login, setLogin, path] = useState( '' );
  const [user, setUser] = useState( {} );
  const [loggedOut, setLoggedOut] = useState( '' );
  const [logoColor, setLogoColor] = useState( false );
  const [logoBackgroundColor, setLogoBackgroundColor] = useState( false );
  const [logoTextColor, setLogoTextColor] = useState( false );
  const [icons, setIcons] = useState( false );
  const [currentPage, setCurrentPage] = useState( false );

  useEffect(() => {
    const localLogin = localStorage.getItem('login');
    // If we have logged in, set it.
    if ( localLogin ) {
      setLogin( localLogin );
    }

    if (allCats === false || allTopics === false) {
      wp.taxonomiesResource = wp.registerRoute( 'wp/v2', 'taxonomies' );

      wp.taxonomiesResource().get()
      .then(res => {
        setAllCats(res.categories)
        setAllTopics(res.topics)
      })
      .catch(err => console.log(err) );
    }
  }, [login]);

  function afterScript() {
    if (icons == false) {
      setIcons(
        new window.SVGMorpheus(
          document.getElementById(`morph-svg`)
        )
      )
    }
  }

    return (
        <div className="container">
          <InlineCss color={logoBackgroundColor} property="background-color" selector={'body.loading'} />
          <InlineCss color={logoColor} property="fill" selector={'a.logo-homepage > svg path.st2, a.logo > svg path'} />
          <InlineCss color={logoBackgroundColor} property="fill" selector={'a.logo-homepage > svg path.st0, .logo svg.simple-logo path.st0'} />
          <InlineCss color={logoTextColor} property="fill" selector={'a.logo-homepage > svg path.st3'} />
          {logoColor && logoBackgroundColor &&
            <LogoButton icons={icons} />
          }
          {!login && false &&
            <MenuLogIn setLogin={setLogin} loggedOut={loggedOut} />
          }
          {login && false &&
            <MenuLoggedIn token={login} setLogin={setLogin} setUser={setUser} user={user} setLoggedOut={setLoggedOut} loggedOut={loggedOut} />
          }
          <Menu token={login} setLogin={setLogin} setUser={setUser} user={user} setLoggedOut={setLoggedOut} loggedOut={loggedOut} setLogoColor={setLogoColor} setLogoBackgroundColor={setLogoBackgroundColor} setLogoTextColor={setLogoTextColor} />
          {logoColor && logoBackgroundColor &&
            <>
              <Link to="/ugens-mest-besogte" />
              {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|facebook|googlebot|google|linkedin|twitterbot|twitter/i.test(navigator.userAgent) === false && navigator.userAgent != 'ReactSnap' &&
                <Route path="" component={Homepage} />
              }
              {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|facebook|googlebot|google|linkedin|twitterbot|twitter/i.test(navigator.userAgent) || navigator.userAgent == 'ReactSnap') &&
                <>
                  <Fragment>
                    <Route exact path="/" component={Homepage} />
                    {false &&
                      <Route exact path="/artikel/:slug/" component={PostPage} test={'aaaa'} loggedIn={login} />
                    }
                    <Route
                      exact
                      path="/artikel/:slug/"
                      render={
                        (props) => <PostPage {...props}  user={user} loggedIn={login} />
                      }
                    />

                    <Switch>
                      <Route exact path="/kategori/:slug/" component={CategoryPage} />
                      <Route exact path="/tema/:slug/" component={TopicPage} />

                      <Route exact path="/artikler/:slug/" component={TagPage} />
                      <Route exact path="/redaktion/:username/" component={UserPage} />
                      <Route exact path="/serie/:serie/:slug/" component={SerieOverview} />
                      <Route exact path="/resultater" component={Resultater} />
                      <Route exact path="/nyt-og-aktuelt/" component={LatestPosts} />
                      <Route exact path="/ugens-mest-besogte/" component={MostVisited} />
                      <Route
                        exact
                        path="/mit-gemte-indhold/"
                        render={
                          (props) => <SavedContent {...props}  user={user} loggedIn={login} />
                        }
                      />
                      <Route
                        exact
                        path="/debat/"
                        render={
                          (props) => <Debat {...props} user={user} loggedIn={login} />
                        }
                      />
                      <Route
                        exact
                        path="/billedgalleri/"
                        render={
                          (props) => <Billedgalleri {...props} loggedIn={login} />
                        }
                      />
                      <Route
                        exact
                        path="/:slug/"
                        render={
                          (props) => <Page {...props} user={user} loggedIn={login} />
                        }
                      />
                    </Switch>
                  </Fragment>
                  <Filter categories={allCats} topics={allTopics} />
                </>
              }
            </>
          }
        </div>
    )
}

function InlineCss(props) {
  return (
    Parse("<style>"+props.selector+" {"+props.property+": "+props.color+"!important;}</style>")
  )
}

function InlineCss2(props) {
  return (
    Parse("<style>"+props.selector+" {background-color: "+props.color+"!important;}</style>")
  )
}

// export default App
