import React, { Component, Fragment, useState } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import '../styles/post-list.css'
import '../styles/homepage.css'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import { ReactComponent as CloseIcon } from '../assets/svg/filter-close.svg';
import SearchResultLlist from '../components/SearchResultLlist';
import Moment from 'moment';
import GalleryModal from '../components/GalleryModal'
import Modal from 'react-modal';
import Parse from 'html-react-parser';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { motion } from "framer-motion"

export default class Billedgalleri extends React.Component {

  constructor(props) {
    super(props);
    if (this.props.loggedIn.length < 1) {
      this.props.history.push('/')
    }
    this.state = {
      posts: [],
      setIsOpen: false,
      isLoaded: false,
      customStyles: {
        content : {
          height                : '100%',
          width                 : '100%',
          padding               : 0,
          border                : 'none',
          'borderRadius'       : '0',
          position: 'relative',
          transform: 'none',
          inset: 'unset',
          bottom: 0,
          'overflowY': 'scroll',
          'marginRight': 0,
          top: 0,
          left: 0,
        },
        overlay: {
          zIndex                : '9',
          border                : 'none',
          marginTop             : '32px',
        },
      },
      modalPost: false,
    }
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    wp.galleriResource = wp.registerRoute( 'wp/v2', 'get-galleri' );

    wp.galleriResource().get()
    .then(res => this.setState({
      posts: res.posts,
      isLoaded: true
    }))
    .catch(err => console.log(err));
  }

  render() {
    const {posts, isLoaded, setIsOpen, customStyles, modalPost} = this.state;
    setTimeout(function(){ document.body.classList.remove('loading'); },500);

    if (isLoaded) {
      return (
        <ListItems posts={posts} customStyles={customStyles}/>
      )
    }
    return ''
  }
}

const ListItems = (props, isVisible) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const [modalPost,setModalPost] = useState(false);

  function openModal(e) {
    e.preventDefault()
    var postKey = e.currentTarget.dataset.postkey
    setModalPost(props.posts[postKey])
    setIsOpen(true)
  }

  function closeModal(e){
    e.preventDefault()
    setIsOpen(false)
  }

  return (
    <>
      {Parse('<style>body:not(.loading) .filder-button{display:none!important}</style>')}
      <div className="background-cacoa category-top gallery-top">
        <div className="samling-result top-content">
          <h1 className="color-dark">billedgalleri</h1>
          <div className="subtitle">fantastiske minder<span><span className="border">|</span><span>{props.posts.length}</span> albums</span></div>
        </div>
        <SearchResultsWave />
      </div>
      {props.posts !== false && props.posts.map((post, i) => {
        const options = {
          buttons: {
            backgroundColor: "#ffffff00",
            showAutoplayButton: false,
            showDownloadButton: false,
            showThumbnailsButton: false,
          },
          settings: {
            lightboxTransitionSpeed: 0.1,
            slideTransitionSpeed: 0.1,
          }
        };
        console.log(post);

        return (
          <section key={i} className={`posts-list-slider gallery-slider ${i == 0 ? "samling-margin-top" : ""}`}>
            <div className="post-slider-top">
              <h3>{post.title}<span className="border">|</span><span className="upload-date">{Moment(post.date).format('DD.MM.YYYY')}</span></h3>
              <a href="/" onClick={openModal} data-postkey={i} className="arrow-right">
                <span>Se alle</span>
                <ArrowRight />
              </a>
            </div>
            <div class="album-description">
              {post.text && Parse(post.text)}
            </div>
            <div className="posts-list gallery-list">
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  {post.images !== false && post.images.map((image, i) => {
                    return (
                      <a key={i} href={image.url}>
                        <img src={image.sizes.medium} alt="" />
                      </a>
                    )
                  })}
                </SRLWrapper>
              </SimpleReactLightbox>
            </div>
          </section>
        )
       })}
       {props.setIsOpen &&
         Parse('<style>.ReactModal__Overlay:after{content:"";top: 1px;width:calc(100% - 36px);border-bottom: 1px solid transparent;height: 27px;position: absolute;border-left: 18px solid white;border-right: 18px solid white;border-top: 7px solid white;transform: translateY(-100%)}</style>')
       }
       <Modal
         isOpen={modalIsOpen}
         onRequestClose={closeModal}
         style={props.customStyles}
         closeTimeoutMS={200}
       >
         {modalPost &&
           <GalleryModal closeModal={closeModal} images={modalPost.images} title={modalPost.title} date={Moment(modalPost.date).format('DD.MM.YYYY')} />
         }
       </Modal>
    </>
  )
}
