import React, { Component, Fragment, useState, useEffect } from 'react'
import { ReactComponent as Logo2 } from '../assets/logo.svg';
import { ReactComponent as Logo } from '../assets/svg/logo-new.svg';
import { ReactComponent as LogoHome } from '../assets/logo-homepage.svg';
import { ReactComponent as LogoFull } from '../assets/svg/logo-full-n.svg';
import { ReactComponent as SvgLogo } from '../assets/svg/logo-morph.svg';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';

const LogoButton = (props) =>{

    const location = useLocation()
    const history = useHistory()

    function getBack(e) {
      e.preventDefault()
      history.goBack()
    }

    if (location.pathname === '/') {
      return (
        <>
          <Link
            className='logo-homepage'
            to={{
              pathname: '/',
            }}
            style={{display:'none',opacity:0,transition: 'opacity 0.3s ease-in-out'}}
          >
            <LogoHome />
          </Link>
          <Link
            className='logo homepage-small'
            to={{
              pathname: '/',
            }}
            style={{display:'none',transition: 'all 0.3s ease-in-out'}}
          >
            <Logo />
          </Link>
          <Link
            className='logo homepage-full'
            to={{
              pathname: '/',
            }}
            style={{display:'none',transition: 'all 0.3s ease-in-out'}}
          >
            <LogoFull />
          </Link>
        </>
      )
    }

    return (
      <>
        <Link
          className='logo'
          to={{
            pathname: '/',
          }}
          style={{display:'none',opacity:0,transition: 'opacity 0.3s ease-in-out'}}
        >
          <Logo />
        </Link>
        <a href="/" onClick={getBack} className={`post-exit-button ${history.location.pathname.includes('artikel') ? 'post-page' : ''}`}><ArrowRight/></a>
      </>
    )
}

export default LogoButton
