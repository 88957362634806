import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import '../styles/post-list.css'
import '../styles/homepage.css'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import PostListSlider from '../components/PostListSlider';
import SearchResultLlist from '../components/SearchResultLlist';
import TopicsSlider from '../components/TopicsSlider';
import {Helmet} from "react-helmet";

export class LatestPosts extends React.Component {
  state = {
    posts: [],
    popular: [],
    isLoaded: false
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    wp.latestResource = wp.registerRoute( 'wp/v2', 'latest' );

    wp.latestResource().get()
    .then(res => this.setState({
      posts: res.posts,
      popular: res.popular,
      isLoaded: true
    }))
    .catch(err => console.log(err));

  }

  render() {
    const {posts, popular, isLoaded} = this.state;
    if (isLoaded) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);

      return (
        <div className="inner-container">
          <Helmet>
              <title>{'Nyt og aktuelt'}</title>
          </Helmet>
          <div className="samling-top background-cacoa">
            <SearchResultsWave />
          </div>
          <div className="samling-result top-content">
            <h1 className="color-dark">nyt og aktuelt</h1>
            <div className="subtitle">de Nyeste indlæg<span><span className="border">|</span> <span>{posts.length}</span> indlæg</span></div>
          </div>

          <SearchResultLlist posts={posts} />

          <div className="page-content samling-content">
            <PostListSlider slider={popular} wave={true} />
          </div>
        </div>
      );
    }
    return ''
  }
}

export default LatestPosts
