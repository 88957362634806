import React, { Component, Fragment, useState, useEffect, useParams } from 'react'
import '../styles/single-post.css'
import { Link } from 'react-router-dom';
import Parse from 'html-react-parser';
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as GlassesIcon } from '../assets/svg/glasses.svg';
import { ReactComponent as AudioIcon } from '../assets/svg/earphones.svg';
import { ReactComponent as VideoIcon } from '../assets/svg/video.svg';
import { ReactComponent as LetterIcon } from '../assets/svg/letter-icon.svg';
import { ReactComponent as VideosIcon } from '../assets/svg/videos.svg';
import { ReactComponent as FireIcon } from '../assets/svg/fire.svg';
import { ReactComponent as PinkWave } from '../assets/svg/pink-block-wave.svg';
import { ReactComponent as YellowWave } from '../assets/svg/yellow-block-wave.svg';
import { ReactComponent as YellowPopWave } from '../assets/svg/post-text-wave.svg';
import VisibilitySensor from 'react-visibility-sensor';
import { motion } from "framer-motion"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { ReactComponent as FilterCloseIcon } from '../assets/svg/filter-close.svg';

const centeredStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
};
const h2Styles = {
  fontSize: "82px",
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

const PostListSlider = (props, isVisible) => {

  const [visibleSlider,setVisibleSlider] = useState(false);
  const [visibleTitle,setVisibleTitle] = useState(false);
  const random = getRandomInt(1, 9999)
  const options = {
    buttons: {
      backgroundColor: "#ffffff00",
      showAutoplayButton: false,
      showDownloadButton: false,
      showThumbnailsButton: false,
    },
    settings: {
      lightboxTransitionSpeed: 0.1,
      slideTransitionSpeed: 0.1,
    },
    thumbnails: {
      showThumbnails: false,
    }
  };

  if (props.slider.content === undefined) {
    return ''
  }

  function onChange (isVisible2) {
    if (isVisible2) {
      setVisibleSlider(true)
    } else {
      // setVisibleSlider(false)
    }
  }
  function onChange2 (isVisible2) {
    if (isVisible2) {
      setVisibleTitle(true)
    } else {
      // setVisibleSlider(false)
    }
  }

  function openImageModal(e, postType) {
    if (postType === 'image') {
      e.preventDefault()
    }
  }

  return (
      <motion.section animate={{ opacity: isVisible ? 1 : 0 }}
        style={{backgroundColor: (props.slider.type === 'series' || (props.slider.type === 'similar' && props.postType !== 'quiz') || props.slider.type === 'cat_posts' || props.slider.type === 'topic_posts') ? props.slider.color : ''}}
        className={`posts-list-slider
          ${props.theme === 'themed' ? "with-background background has-top-vawe" : ""}
          ${props.slider.type === 'series' ? " with-background background has-top-vawe" : ""}
          ${props.slider.type === 'cat_posts' ? "with-background background-light-yellow has-top-vawe custom-icon" : ""}
          ${props.slider.type === 'similar' ? "with-background background-light-yellow has-top-vawe custom-icon similar-posts" : ""}
          ${props.slider.type === 'topic_posts' ? "with-background background-light-yellow has-top-vawe custom-icon" : ""}
          ${props.slider.type === 'pop_posts' && props.wave !== undefined ? "background-yellow with-background background has-top-vawe" : ""}
          ${visibleSlider ? "is-visible" : ""}
          ${visibleTitle ? "title-is-visible" : ""}
      `}>
        {props.slider.type === 'series' &&
          <>
            <SvgColor color={props.slider.color} selector={'div.posts-slider-top-vawe.series-'+random+' > svg path'} />
            <div className={'posts-slider-top-vawe series-'+random}>
              {props.slider.content_type === 'video' &&
                <YellowPopWave />
              }
              {props.slider.content_type !== 'video' &&
                <PinkWave />
              }
            </div>
            <div className="block-icon">
              {props.slider.content_type === 'text' && props.slider.category.name !== "Lejrbålet" &&
                <GlassesIcon />
              }
              {props.slider.content_type === 'text' && props.slider.category.name === "Lejrbålet" &&
                <LetterIcon />
              }
              {props.slider.content_type === 'podcast' &&
                <AudioIcon />
              }
              {props.slider.content_type === 'video' &&
                <VideosIcon />
              }
              {props.slider.content_type === 'quiz' && false &&
                <LetterIcon />
              }
            </div>
          </>
        }
        {props.slider.type === 'similar' && props.postType !== 'quiz' &&
          <>
            <SvgColor color={props.slider.color} selector={'div.posts-slider-top-vawe.series-'+random+' > svg path'} />
            <div className={'posts-slider-top-vawe series-'+random}>
              <PinkWave />
            </div>

          </>
        }
        {(props.slider.type === 'cat_posts' || props.slider.type === 'topic_posts') &&
          <>
            <SvgColor color={props.slider.color} selector={'div.posts-slider-top-vawe.cat-slider-'+random+' > svg path'} />
            <div className={"posts-slider-top-vawe cat-slider-"+random}>
              <YellowWave />
            </div>
            <div className="block-icon fire">
              {props.slider.icon && props.slider.icon.url &&
                <>
                  <img src={props.slider.icon.url} />
                </>
              }
            </div>
          </>
        }
        {props.slider.type === 'pop_posts' && props.wave !== undefined &&
          <>
            <div className="posts-slider-top-vawe">
              <YellowPopWave />
            </div>
          </>
        }
        <VisibilitySensor onChange={onChange2}>
        <div className={`post-slider-top ${props.theme === 'white' || (props.wave !== undefined && props.slider.type === 'pop_posts') ? 'button-to-full-list' : ''}`}>
          {(props.slider.type === 'new_posts' || props.slider.type === 'tag_posts' || props.slider.type === 'pop_posts' || props.slider.type === 'similar') &&
            <Fragment>
              {props.slider.type === 'new_posts' &&
                <>
                  <h3>nyt og aktuelt</h3>
                  <Link
                    to={{
                      pathname: '/nyt-og-aktuelt',
                      state: {
                        posts: props.posts,
                        popular: props.slider.content
                      }
                    }}
                    className="arrow-right">
                     <span>Se alle</span>
                     <ArrowRight />
                  </Link>
                </>
              }
              {props.slider.type === 'tag_posts' &&
                <>
                  <h3>{props.slider.tag_name}</h3>
                  <Link
                    to={{
                      pathname: '/artikler/'+props.slider.slug,
                    }}
                    className="arrow-right">
                     <span>Se alle</span>
                     <ArrowRight />
                  </Link>
                </>
              }
              {props.slider.type === 'pop_posts' &&
                <>
                  <h3>ugens mest besøgte</h3>
                  <Link
                    to={{
                      pathname: '/ugens-mest-besogte',
                    }}
                    className="arrow-right">
                     <span>Se alle</span>
                     <ArrowRight />
                  </Link>
                </>
              }
              {props.slider.type === 'similar' &&
                <>
                  {props.postType !== 'quiz' ?
                    <h3>Lignende artikler</h3>
                    :
                    <h3>quizzer & tests</h3>
                  }
                  <Link
                    to={{
                      pathname: props.slider.url,
                    }}
                    className="arrow-right">
                     <span>Se alle</span>
                     <ArrowRight />
                  </Link>
                </>
              }
            </Fragment>
          }
          {props.slider.type === 'series' && props.slider.content[0] !== undefined &&
            <Fragment>
              {props.slider.category && props.slider.content_type === 'podcast' &&
                <Fragment>
                  <h3>{props.slider.title}</h3>
                  <div className="subtitle">Podcast serie <span>| {props.slider.total} episoder</span></div>
                  <Link
                    to={{
                      pathname: '/serie/podcast/'+props.slider.category.slug,
                      state: {
                        id: props.slider.category.term_id,
                      }
                    }}
                    className="button background-dark color-white with-earphone-icon"
                  >
                    <AudioIcon />Lyt til hele serien
                  </Link>
                </Fragment>
              }
              {props.slider.category && props.slider.content_type === 'text' && props.slider.category.name === "Lejrbålet" &&
                <Fragment>
                  <h3>{props.slider.title}</h3>
                  <div className="subtitle">Brevkasse <span>| {props.slider.total} episoder</span></div>
                  <Link
                    to={{
                      pathname: '/serie/artikler/'+props.slider.category.slug,
                      state: {
                        id: props.slider.category.term_id,
                      }
                    }}
                    className="button background-dark color-white with-earphone-icon"
                  >
                    <GlassesIcon />Læs alle spørgsmål
                  </Link>
                </Fragment>
              }
              {props.slider.category && props.slider.content_type === 'text' && props.slider.category.name !== "Lejrbålet" &&
                <Fragment>
                  <h3>{props.slider.title}</h3>
                  <div className="subtitle">Artikel serie <span>| {props.slider.total} episoder</span></div>
                  <Link
                    to={{
                      pathname: '/serie/artikler/'+props.slider.category.slug,
                      state: {
                        id: props.slider.category.term_id,
                      }
                    }}
                    className="button background-dark color-white with-earphone-icon"
                  >
                    <GlassesIcon />Læs alle artikler
                  </Link>
                </Fragment>
              }
              {props.slider.category && props.slider.content_type === 'video' &&
                <Fragment>
                  <h3>{props.slider.title}</h3>
                  <div className="subtitle">Video serie <span>| {props.slider.total} episoder</span></div>
                  <Link
                    to={{
                      pathname: '/serie/video/'+props.slider.category.slug,
                      state: {
                        id: props.slider.category.term_id,
                      }
                    }}
                    className="button background-dark color-white with-earphone-icon"
                  >
                    <VideoIcon />Se alle videoer
                  </Link>
                </Fragment>
              }
              {props.slider.category && props.slider.content_type === 'quiz' &&
                <Fragment>
                  <h3>{props.slider.title}</h3>
                  <div className="subtitle">Prøv Nektars quizzer og tests <span>| {props.slider.total} spørgsmål</span></div>
                  <Link
                    to={{
                      pathname: '/serie/quiz/'+props.slider.category.slug,
                      state: {
                        id: props.slider.category.term_id,
                      }
                    }}
                    className="button background-dark color-white with-earphone-icon"
                  >
                    <GlassesIcon />Se alle quizzer & tests
                  </Link>
                </Fragment>
              }
            </Fragment>
          }
          {(props.slider.type === 'cat_posts') &&
            <Fragment>
              <h3>{props.slider.cat_name}</h3>
              <div className="subtitle">{props.slider.slider_subtitle} <span>| {props.slider.content.length} opslag</span></div>
              <Link
                to={{
                  pathname: '/kategori/'+props.slider.slug,
                }}
                className="button background-dark color-white arrow-right"
              >
                {props.slider.button_text}<ArrowRight />
              </Link>
            </Fragment>
          }
          {(props.slider.type === 'topic_posts') &&
            <Fragment>
              <h3>{props.slider.topic_name}</h3>
              <div className="subtitle">{props.slider.slider_subtitle} <span>| {props.slider.content.length} opslag</span></div>
              <Link
                to={{
                  pathname: '/tema/'+props.slider.slug,
                }}
                className="button background-dark color-white arrow-right"
              >
                {props.slider.button_text}<ArrowRight />
              </Link>
            </Fragment>
          }
        </div>
        </VisibilitySensor>
        <VisibilitySensor onChange={onChange}>
          <ul className="posts-list">
            {props.slider.type !== 'topics' && props.slider.content !== undefined && props.slider.content.map((post, i) => {
              var lejrbaletCat = false
              return (
                <li key={i}>
                  <Link
                    to={{
                      pathname: '/artikel/'+post.slug,
                      state: {
                        id: post.id,
                        hideFilter: true
                      }
                    }}
                    onClick={(e) => openImageModal(e, post.post_type)}
                  >
                    <div className="post-background-image">
                      {post.thumbnail && post.thumbnail.sizes &&
                        <>
                          {post.post_type === 'image' &&
                            <SRLWrapper  options={options}>
                              <img src={post.thumbnail.sizes.medium} alt="" />
                            </SRLWrapper>
                          }
                          {post.post_type !== 'image' &&
                            <img src={post.thumbnail.sizes.medium} alt="" />
                          }
                        </>
                      }
                      {post.post_type !== 'image' && post.post_type !== 'quiz' &&
                        <>
                          {post.categories && post.categories.forEach(postCat => {
                            if (postCat.slug === 'lejrbaalet') {
                              lejrbaletCat = true
                            }
                          })}
                          {( (lejrbaletCat && post.post_type !== 'text') || !lejrbaletCat ) &&
                            <div className="post-type-icon background-white">
                              {post.post_type === 'text' &&
                                <GlassesIcon />
                              }
                              {post.post_type === 'podcast' &&
                                <AudioIcon />
                              }
                              {post.post_type === 'video' &&
                                <VideoIcon />
                              }
                              {post.post_type === 'quiz' &&
                                <LetterIcon />
                              }
                            </div>
                          }
                        </>
                      }
                    </div>
                  </Link>
                  <div className="categories-list">
                    {post.categories !== false && post.categories !== undefined && post.categories.map((category, i) => {
                      return (
                        <Link
                          className={`category-tag color-white
                            ${visibleSlider ? 'visibleTaxonomy' : ''}
                          `}
                          key={i}
                          style={{backgroundColor:post.category_color}}
                          to={{
                            pathname: '/kategori/'+category.slug,
                          }}
                        >
                          {category.name}
                        </Link>)
                    })}
                    {post.topics !== false && post.topics !== undefined && post.topics.map((topic, i) => {
                      return (
                        <Link
                          className={`category-theme background-dark color-white
                            ${visibleSlider ? 'visibleTaxonomy' : ''}
                          `}
                          key={i}
                          to={{
                            pathname: '/tema/'+topic.slug,
                          }}
                        >
                          {topic.name}
                        </Link>)
                    })}
                  </div>
                  <Link
                    to={{
                      pathname: '/artikel/'+post.slug,
                      state: {
                        id: post.id,
                        hideFilter: true
                      }
                    }}
                    onClick={(e) => openImageModal(e, post.post_type)}
                  >
                    <h3
                      style={{
                        color: props.slider.type === 'tag_posts' || (props.slider.type === 'similar' && props.postType === 'quiz') ? post.category_color : (props.type === 'ugens mest besøgte' && props.theme === 'themed' ? post.category_color : '')
                      }}
                      className={props.slider.type === 'series' ? 'color-white' : ''}
                    >{post.title}</h3>
                    <div className="post-info">
                      <div
                        style={{
                          color: props.slider.type === 'tag_posts' || (props.slider.type === 'similar' && props.postType === 'quiz') ? post.category_color : (props.type === 'ugens mest besøgte' && props.theme === 'themed' ? post.category_color : '')
                        }}
                        className={`upload-date ${props.slider.type === 'series' ? "color-white" : ""}`}
                      >{post.date} <span className="border">|</span>
                      </div>
                      <div
                        style={{
                          color: props.slider.type === 'tag_posts' || (props.slider.type === 'similar' && props.postType === 'quiz') ? post.category_color : (props.type === 'ugens mest besøgte' && props.theme === 'themed' ? post.category_color : '')
                        }}
                        className={`created-by ${props.slider.type === 'series' ? "color-white" : ""}`}
                      >
                        {post.authors !== false && post.authors.map((author, i) => {
                          if (i > 0) {
                            return (
                              <Link
                                key={i}
                                to={{
                                  pathname: '/redaktion/'+author.user_nicename,
                                }}
                              >
                                 og {author.display_name}
                              </Link>
                            )
                          }
                          return (
                            <Link
                              key={i}
                              to={{
                                pathname: '/redaktion/'+author.user_nicename,
                              }}
                            >
                              {author.display_name+' '}
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  </Link>
                </li>
              )
             })}
          </ul>
        </VisibilitySensor>
      </motion.section>
  );
}

export default PostListSlider

function SvgColor(props) {
  return (
    Parse("<style>"+props.selector+" {fill: "+props.color+"!important;}</style>")
  )
}

function SliderIcon(props) {
  return (
    Parse(props.url)
  )
}
