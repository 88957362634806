import React, { Component, Fragment, useState } from 'react'
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as GlassesIcon } from '../assets/svg/glasses.svg';
import { ReactComponent as AudioIcon } from '../assets/svg/earphones.svg';
import { ReactComponent as VideoIcon } from '../assets/svg/video.svg';
import { ReactComponent as LetterIcon } from '../assets/svg/letter-icon.svg';
import VisibilitySensor from 'react-visibility-sensor';

export class SearchResultLlist extends React.Component {
  state = {
    visibleSlider: false,
  }

  render() {
    return (
      <ul className="search-result-list">
        {this.props.posts && this.props.posts.map((post, i) => {
          return (
            <ListItem key={i} post={post}/>
          )
        })}
      </ul>
    );
  }
}

export default SearchResultLlist


const ListItem = (props, isVisible) => {
  const [visibleSlider,setVisibleSlider] = useState(false);
  const [visibleCats,setVisibleCats] = useState(false);

  function onChange (isVisible) {
    if (isVisible) {
      // setVisibleSlider(true)
    }
  }
  function onChange2 (isVisible) {
    if (isVisible) {
      setVisibleCats(true)
    }
  }

  return (
    <VisibilitySensor onChange={onChange}>
      <li className={`${visibleCats ? 'is-visible' : ''} ${visibleCats ? 'title-is-visible' : ''}`}>
        <div className="search-result-left">
          <Link
            to={{
              pathname: '/artikel/'+props.post.slug,
              state: {
                id: props.post.id,
                hideFilter: true
              }
            }}
          >
            <div className="post-background-image">
              {props.post.thumbnail && props.post.thumbnail.sizes &&
                <img src={props.post.thumbnail.sizes.medium} alt="" />
              }
              <div className="post-type-icon background-white">
                {props.post.post_type === 'text' &&
                  <GlassesIcon />
                }
                {props.post.post_type === 'podcast' &&
                  <AudioIcon />
                }
                {props.post.post_type === 'video' &&
                  <VideoIcon />
                }
                {props.post.post_type === 'quiz' &&
                  <LetterIcon />
                }
              </div>
            </div>
          </Link>
        </div>
        <div className={`search-result-right`}>
          <VisibilitySensor onChange={onChange2}>
            <div className="categories-list">
              {props.post.categories !== false && props.post.categories.map((category, i) => {
                return (
                <Link
                  to={{
                    pathname: '/kategori/'+category.slug,
                    state: {
                      id: category.term_id
                    }
                  }}
                  key={i} style={{backgroundColor:props.post.category_color}} className="category-tag color-white">
                  {category.name}
                </Link>)
              })}
              {props.post.topics !== false && props.post.topics.map((topic, i) => {
                return (
                <Link
                  to={{
                    pathname: '/tema/'+topic.slug,
                    state: {
                      id: topic.term_id
                    }
                  }}
                  key={i} className="category-theme background-dark color-white">
                  {topic.name}
                </Link>)
              })}
            </div>
          </VisibilitySensor>
          <Link
            to={{
              pathname: '/artikel/'+props.post.slug,
              state: {
                id: props.post.id,
                hideFilter: true
              }
            }}
          >
            <h3 style={{color: props.post.category_color}}>{props.post.title}</h3>
            <div className="post-info">
              <div style={{color: props.post.category_color}} className="upload-date">{props.post.date} <span className="border">|</span></div>
              <div style={{color: props.post.category_color}} className="created-by">
                {props.post.authors !== false && props.post.authors.map((author, i) => {
                  if (i > 0) {
                    return (
                      <Link
                        key={i}
                        to={{
                          pathname: '/redaktion/'+author.user_nicename,
                        }}
                      >
                         og {author.display_name}
                      </Link>
                    )
                  }
                  return (
                    <Link
                      key={i}
                      to={{
                        pathname: '/redaktion/'+author.user_nicename,
                      }}
                    >
                      {author.display_name+' '}
                    </Link>
                  )
                })}
              </div>
            </div>
          </Link>
        </div>
      </li>
    </VisibilitySensor>
  )
}
