import React, { Component, Fragment, useState, useEffect, useParams } from 'react'
import Parse from 'html-react-parser';
import { ReactComponent as Wave } from '../assets/svg/landing-page-wave.svg';
import { ReactComponent as Arrow } from '../assets/svg/landing-arrow.svg';
import { ReactComponent as Glasses } from '../assets/svg/landing-glasses.svg';
import { ReactComponent as Headphones } from '../assets/svg/landing-headphones.svg';
import { ReactComponent as Phone } from '../assets/svg/landing-phone.svg';
import { ReactComponent as Play } from '../assets/svg/video-play-landing.svg';
import { ReactComponent as FooterLogo } from '../assets/svg/footer-logo-landing.svg';
import { ReactComponent as LogoHome } from '../assets/logo-homepage.svg';

import '../styles/landingpage.css'
// React.lazy(() => import('../styles/landingpage.css'));

const LandingPage = (props) => {

  return (
    <>
      <div className="landing-page">
        <div className="background-color" style={{backgroundColor: '#FBF2EC'}}>
          <a href="/" className="logo-homepage landing">
            <LogoHome />
          </a>
          <div className="top-container">
            <div className="top-block" style={{backgroundColor: 'rgb(135, 204, 161)'}}>
            </div>
            <div className="top-vawe">
              <Wave />
            </div>
            <div className="top-text-block">
              <h2>{props.page.top_section.heading}</h2>
              {Parse(props.page.top_section.middle_text)}
              <div className="bottom-text-content">
                <Phone />
                <h3>{props.page.top_section.bottom_text}</h3>
                <div className="landing-arrow">
                  <Arrow />
                </div>
              </div>
            </div>
            <div className="images">
              <div className="image second">
                <img src={props.page.top_section.image.url} alt="" />
              </div>
            </div>
          </div>
          <div className="bottom-content">
            <h2>{props.page.middle_section.heading}</h2>
            {Parse(props.page.middle_section.middle_text)}
            <a target={`${props.page.middle_section.open_link_in_a_new_window ? '_blank' : ''}`} href={props.page.middle_section.button_link} className="button">{props.page.middle_section.button_text}</a>
          </div>
          <div className="landing-headphones">
            <Headphones />
          </div>
          <div className="landing-video-play">
            <Play />
          </div>
          <div className="landing-glasses">
            <Glasses />
          </div>
        </div>
        <div className="landing-footer">
          <FooterLogo />
          {Parse(props.page.bottom_section.contacts)}
        </div>
      </div>
    </>
  );
}

export default LandingPage
