import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { wp } from '../index.js'
import axios from 'axios'
import Parse from 'html-react-parser';
import { ReactComponent as VideoIcon } from '../assets/svg/video.svg';
import { ReactComponent as MessagesIcon } from '../assets/svg/messeges-icon.svg';
import { ReactComponent as PlayIcon } from '../assets/svg/play.svg';
import { ReactComponent as GlassesIcon } from '../assets/svg/glasses.svg';
import { ReactComponent as LetterIcon } from '../assets/svg/letter-icon.svg';
import { ReactComponent as PlayPause } from '../assets/svg/playpause.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import { ReactComponent as CheckIcon } from '../assets/svg/check-icon.svg';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import {Helmet} from "react-helmet";

class SerieOverview extends Component {
  state = {
    serie: {},
    isLoaded: false,
    shareOpen: false,
    startedPodcasts: {},
    finishedPodcasts: localStorage.nektar_podcasts_finished ? localStorage.nektar_podcasts_finished : []
  }

  constructor(props) {
    super(props);
    this.handleShare = this.handleShare.bind(this);
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    this.getSerie()
  }

  getSerie() {
    var catSLug = this.props.match.params.slug;
    var type = this.props.match.params.serie
    const _this = this;

    const dataArray = new FormData();
    dataArray.append("slug", catSLug);
    dataArray.append("type", type);

    axios
    .post("/wp-json/wp/v2/get-serie", dataArray, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(
      function(res) {
        _this.setState({
          serie: res.data,
          isLoaded: true,
        })

        if (res.data.posts !== undefined) {
          var startedPodcasts = JSON.parse(localStorage.nektar_podcasts)
          res.data.posts.map((post, i) => {
            if (localStorage.nektar_podcasts !== undefined && Object.keys(startedPodcasts).length !== 0 && startedPodcasts.constructor === Object) {
              Object.keys(startedPodcasts).map((startedPodcastId, i) => {
                if (post.id == startedPodcastId) {
                  var startedPodcast = startedPodcasts.[startedPodcastId]
                  var postId = post.id
                  var progress = (100 * startedPodcast.currentTime) / startedPodcast.duration
                  var newObj = _this.state.startedPodcasts
                  Object.assign(newObj, {[postId]: progress});

                  _this.setState({
                    startedPodcasts: newObj
                  })
                }
              })
            }

          })
        }

      }
    )
    .catch(err => console.log(err));
  }

  handleShare(e) {
    e.preventDefault()
    this.setState({
      shareOpen: !this.state.shareOpen,
    })
  }

  render() {
    const {serie, isLoaded, shareOpen, startedPodcasts, finishedPodcasts } = this.state;
    if (isLoaded) {
      if (serie.posts === undefined) {
        this.props.history.push('/')
        return ''
      }
      setTimeout(function(){ document.body.classList.remove('loading'); },500);
      return (
        <div className="inner-container serie-overview">
          <Helmet>
              <title>{serie.title}</title>
          </Helmet>
          <div style={{backgroundColor:serie.posts[0].category_color}} className="category-top">
          	<div className="top-content color-white">
          		<h1 className="color-white">{serie.title}</h1>
              {serie.type === 'podcast' &&
                <div className="subtitle color-dark">Podcast serie<span><span className="border">|</span><span>{serie.posts.length}</span> episoder</span></div>
              }
              {serie.type === 'video' &&
                <div className="subtitle color-dark">Video serie<span><span className="border">|</span><span>{serie.posts.length}</span> episoder</span></div>
              }
              {serie.type === 'text' &&
                <div className="subtitle color-dark">Artikel serie<span><span className="border">|</span><span>{serie.posts.length}</span> episoder</span></div>
              }
              {serie.type === 'quiz' &&
                <div className="subtitle color-dark">Brevkasse<span><span className="border">|</span><span>{serie.posts.length}</span> episoder</span></div>
              }
          		<div className="buttons">
                <Link
                  to={{
                    pathname: '/artikel/'+serie.posts[0].slug,
                    state: {
                      hideFilter: true
                    }
                  }}
                  className="button background-dark color-white"
                >
                  {serie.type === 'podcast' &&
                    <>
                      <VideoIcon />Lyt til den nyeste episode
                    </>
                  }
                  {serie.type === 'video' &&
                    <>
                      <VideoIcon />Se den nyeste episode
                    </>
                  }
                  {serie.type === 'text' &&
                    <>
                      <GlassesIcon />Læse den nyeste artikel
                    </>
                  }
                  {serie.type === 'quiz' &&
                    <>
                      <LetterIcon />Læs det nyeste spørgsmål
                    </>
                  }
                </Link>
          			<a href="/" onClick={this.handleShare} className="button background-white color-dark"><MessagesIcon />Del {serie.title}</a>
                <SwitchTransition mode={'out-in'}>
                  <CSSTransition
                    key={shareOpen}
                    addEndListener={(node, done) => {
                      node.addEventListener("transitionend", done, false);
                    }}
                    classNames="content"
                  >
                    <>
                      {shareOpen &&
                        <div className={'share-container '+(this.shareOpen ? 'active' : '')}>
                          <div className={'share-buttons'}>
                            <EmailShareButton
                              url={window.location.href}
                              subject={serie.title}
                              body={window.location.href}
                             >
                              <EmailIcon size={32} round={true} />
                            </EmailShareButton>
                            <FacebookShareButton
                              url={window.location.href}
                              quote={serie.title}
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <LinkedinShareButton
                              url={window.location.href}
                            >
                              <LinkedinIcon  size={32} round />
                            </LinkedinShareButton>
                            <TwitterShareButton
                            url={window.location.href}
                              title={serie.title}
                              className="Demo__some-network__share-button"
                            >
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                          </div>
                        </div>
                      }
                    </>
                  </CSSTransition>
                </SwitchTransition>
          		</div>
              {Parse(serie.description)}
          	</div>
          	<SearchResultsWave />
          </div>
          <ul className="podcast-list">
            {serie.posts !== false && serie.posts.length > 0 &&  serie.posts.map((post, i) => {
              return (
                <li key={i}>
                  <Link
                    to={{
                      pathname: '/artikel/'+post.slug,
                      state: {
                        hideFilter: true
                      }
                    }}
                  >
                    <div className="podcast-left">
                      <div className="post-background-image">
                        <img src={post.thumbnail.sizes.medium} alt="" />
                      </div>
                    </div>
                    <div className="podcast-middle">
                      <div className="post-info">
                        <div className="upload-date color-dark">{post.episode}<span className="border">|</span></div>
                        <div className="created-by color-dark">{post.date}</div>
                      </div>
                      <h3 style={{color:post.category_color}}>{post.title}</h3>
                      <div className="post-info for-podcast">
                        {post.file &&
                          <div className="upload-date color-dark">{post.file.length_formatted.split(':')[0]} min. {post.file.length_formatted.split(':')[1]} s.<span className="border">|</span></div>
                        }
                        <div className="created-by color-dark">
                          {post.authors !== false && post.authors.map((author, i) => {
                            if (i > 0) {
                              return (' og '+author.display_name)
                            }
                            return (author.display_name)
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="podcast-right">
                      {serie.type === 'podcast' &&
                        (() => {
                            if (finishedPodcasts && finishedPodcasts.includes([post.id]))
                               return (
                                 <div className="finished-podcast">
                                   <PlayPause />
                                   <CheckIcon />
                                 </div>
                               )
                            else if (startedPodcasts && startedPodcasts.[post.id] !== undefined)
                               return (
                                 <div className="started-podcast">
                                   <PlayIcon />
                                   <div className="progress-bar">
                                     <svg viewBox="0 0 36 36" class="circular-chart">
                                       <path class="circle"
                                       stroke-dasharray={startedPodcasts.[post.id]+",100"}
                                       d="M18 2.0845
                                         a 15.9155 15.9155 0 0 1 0 31.831
                                         a 15.9155 15.9155 0 0 1 0 -31.831"
                                       />
                                     </svg>
                                   </div>
                                 </div>
                               )
                             else
                               return (
                                 <div className="play-button">
                                   <PlayIcon />
                                 </div>
                               )
                        })()
                      }
                    </div>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>

      )
    }
    document.querySelector('body').classList.add('loading')
    return ''
  }

}

export default SerieOverview
