import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import '../styles/post-list.css'
import '../styles/homepage.css'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import PostListSlider from '../components/PostListSlider';
import SearchResultLlist from '../components/SearchResultLlist';
import {Helmet} from "react-helmet";

export class TagPage extends React.Component {
  state = {
    posts: [],
    popular: [],
    isLoaded: false
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    wp.tagResource = wp.registerRoute( 'wp/v2', 'get_tag/(?P<slug>)' );
    var tagSlug = this.props.match.params.slug

    wp.tagResource().slug(tagSlug).get()
    .then(res => this.setState({
      posts: res.tag_posts,
      title: res.title,
      popular: res.pop_posts,
      isLoaded: true
    }))
    .catch(err => console.log(err));

  }

  render() {
    const {posts, title, popular, isLoaded} = this.state;
    if (isLoaded) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);

      return (
        <div className="inner-container">
          <Helmet>
              <title>{title}</title>
          </Helmet>
          <div className="samling-top background-cacoa">
            <SearchResultsWave />
          </div>
          <div className="samling-result top-content">
            <h1 className="color-dark">{title}</h1>
            <div className="subtitle">
              {false &&
                <>articles by tag</>
              }
              <span>{false && <span className="border">|</span>}
              <span>{posts.length}</span> indlæg</span>
            </div>
          </div>

          <ul className="search-result-list">
            <SearchResultLlist posts={posts} />
          </ul>

          <div className="page-content samling-content">
            <PostListSlider slider={popular} wave={true} />
          </div>
        </div>
      );
    }
    return ''
  }
}

export default TagPage
