import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import { ReactComponent as CheckIcon } from '../assets/svg/check-icon.svg';
import SearchResultLlist from '../components/SearchResultLlist';

export class Results extends React.Component {
  state = {
    posts: [],
    categories: [],
    topics: [],
    total: '',
    isLoaded: false
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    if (this.props.location.state === undefined) {
      this.props.history.push('/')
    }
    this.setData()
  }

  setData(){
    if (this.props.location.state !== undefined) {
      this.setState({
        posts: this.props.location.state.res.posts,
        categories: this.props.location.state.res.selected_cats,
        topics: this.props.location.state.res.selected_topics,
        total: this.props.location.state.res.total,
        query: this.props.location.state.res.query,
        isLoaded: true
      })
    }
  }

  componentDidUpdate() {
    if (
      this.state.categories !== this.props.location.state.res.selected_cats ||
      this.state.topics !== this.props.location.state.res.selected_topics ||
      this.state.query !== this.props.location.state.res.query
    ) {
      this.setData()
    }
  }

  render() {
    const {isLoaded, posts, total, categories, topics} = this.state;
    if (isLoaded) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);

      return (
        <div className="inner-container">
          <div className="samling-top background-cacoa">
            <SearchResultsWave />
          </div>
          <div className="samling-result top-content">
            <h1 className="color-dark">{total} resultater</h1>
            <div className="categories-list">
              {categories !== false && categories.map((category, i) => {
                return (
                  <button key={i} data-taxtype={'category'} data-taxslug={category.slug} style={{backgroundColor:category.color}} className={`category-tag color-white`}><CheckIcon />{category.name}</button>
                )
              })}
              {topics !== false && topics.map((topic, i) => {
                if (topic.name === null) {
                  return ''
                }
                return (
                  <button key={i} data-taxtype={'topic'} data-taxslug={topic.slug} className={`category-theme background-dark color-white`}><CheckIcon />{topic.name}</button>
                )
              })}
            </div>

          </div>

          <SearchResultLlist posts={posts} />

          <div className="page-content samling-content">
          </div>
        </div>
      );
    }
    return ''
  }
}

export default Results
