import React, { Component, Fragment } from 'react'
import '../styles/single-post.css'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import { wp } from '../index.js'
import axios from 'axios'
import Parse from 'html-react-parser';
import Moment from 'moment';
import { ReactComponent as Logo } from '../assets/svg/logo-new.svg';
import { ReactComponent as TopLeftCorner } from '../assets/svg/top-left-corner.svg';
import { ReactComponent as MessagesIcon } from '../assets/svg/messeges-icon.svg';
import { ReactComponent as BravoIcon } from '../assets/svg/bravo-icon.svg';
import { ReactComponent as StarIcon } from '../assets/svg/star-icon.svg';
import { ReactComponent as PostTextWave } from '../assets/svg/post-text-wave.svg';
import { ReactComponent as Play } from '../assets/svg/play-icon.svg';
import { ReactComponent as PlayPause } from '../assets/svg/playpause.svg';
import { ReactComponent as Pause } from '../assets/svg/pause.svg';
import { ReactComponent as Next } from '../assets/svg/next.svg';
import { ReactComponent as Prev } from '../assets/svg/prev.svg';
import { ReactComponent as CloseIcon } from '../assets/svg/filter-close.svg';
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as CheckIcon } from '../assets/svg/check-icon.svg';
import { ReactComponent as PercentIcon } from '../assets/svg/percent.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import ReactPlayer from 'react-player/lazy'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import Duration from '../components/Duration'
import Quiz from '../components/Quiz'
// import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'
import Slider from 'react-input-slider';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PostListSlider from '../components/PostListSlider';
import SliderSlick from "react-slick";
import {Helmet} from "react-helmet";

class PostPage extends Component {
  state = {
    post: {},
    isLoaded: false,
    playing: false,
    played: 0,
    loaded: 0,
    duration: 0,
    url: null,
    postPath: false,
    visited: false,
    loggedIn: false,
    likes: 0,
    shares: 0,
    saves: 0,
    shareOpen: false,
    isFullscreen: false,
  }

  load = url => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false
    })
  }

  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.handleNotPlaying = this.handleNotPlaying.bind(this);
    this.handleCloseVideo = this.handleCloseVideo.bind(this);
    this.handleSeekMouseDown = this.handleSeekMouseDown.bind(this);
    this.handleSeekMouseUp = this.handleSeekMouseUp.bind(this);
    this.handleSeekChange = this.handleSeekChange.bind(this);
    this.saveProgress = this.saveProgress.bind(this);
    this.saveFinished = this.saveFinished.bind(this);
    this.handleSavePost = this.handleSavePost.bind(this);
    this.handleLikePost = this.handleLikePost.bind(this);
    this.handleSharePost = this.handleSharePost.bind(this);
    this.openSharePost = this.openSharePost.bind(this);
    this.slidePrev = this.slidePrev.bind(this);
    this.slideNext = this.slideNext.bind(this);
    this.getBack = this.getBack.bind(this);
    this.audioPlayer = React.createRef()
    this.videoPlayer = React.createRef()
    this.scrollToRef = React.createRef()
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')

    if (this.state.isLoaded === false) {
      this.getWpPost()
    }
  }

  registerPostVisit(id) {
    axios.get('/wp-json/wp/v2/openpost?id='+id)
    .then()
    .catch(err => console.log(err));
  }

  getWpPost() {
    var slug = this.props.match.params.slug
    wp.posts().slug(slug).embed().get()
      .then(
        res => this.setState({
          postPath: this.props.location.pathname,
          post: res[0],
          isLoaded: true,
          title: Parse(res[0].title.rendered),
          featuredImg: res[0].acf.main_image ? res[0].acf.main_image.sizes.large : false,
          thumbnail: res[0].acf.thumbnail ? res[0].acf.thumbnail.sizes.large : false,
          thumbnailWidth: res[0].acf.thumbnail ? res[0].acf.thumbnail.sizes['large-width'] : false,
          thumbnailHeight: res[0].acf.thumbnail ? res[0].acf.thumbnail.sizes['large-height'] : false,
          authors: res[0].acf.authors,
          caption: res[0].acf.main_image !== undefined ? res[0].acf.main_image.caption : false,
          date: Moment(res[0].date).format('DD.MM.YYYY'),
          textInClrBlack: (res[0].acf.text_content !== undefined && res[0].acf.text_content.text_in_colored_background !== undefined) ? Parse(res[0].acf.text_content.text_in_colored_background) : false,
          textInClrWhite: (res[0].acf.text_content !== undefined && res[0].acf.text_content.text_in_white_background !== undefined) ? Parse(res[0].acf.text_content.text_in_white_background) : false,
          img1: (res[0].acf.text_content !== undefined && res[0].acf.text_content.image_1 !== undefined) ? res[0].acf.text_content.image_1 : false,
          img2: (res[0].acf.text_content !== undefined && res[0].acf.text_content.image_2 !== undefined) ? res[0].acf.text_content.image_2 : false,
          images: (res[0].acf.text_content !== undefined && res[0].acf.text_content.images !== undefined) ? res[0].acf.text_content.images : false,
          postType: res[0].acf.post_type,
          subtitle: res[0].acf.subtitle !== undefined ? res[0].acf.subtitle : false,
          audiofile: res[0].acf.audiofile !== undefined ? res[0].acf.audiofile : false,
          videofile: res[0].acf.video !== undefined ? res[0].acf.video : false,
          episode: res[0].acf.episode !== undefined ? res[0].acf.episode : false,
          postLikes: res[0].acf.post_likes !== undefined ? res[0].acf.post_likes : 0,
          postSaves: res[0].acf.post_saves !== undefined ? res[0].acf.post_saves : 0,
          postShares: res[0].acf.post_shares !== undefined ? res[0].acf.post_shares : 0,
          url: res[0].acf.video !== undefined ? res[0].acf.video.url : null,
          videoOrImage: res[0].acf.video_or_featured_image !== undefined ? res[0].acf.video_or_featured_image : 'image',
          topVideo: res[0].acf.video_or_featured_image == 'video' ? res[0].acf.video : false,
          loggedIn: this.props.loggedIn,
          likedThisPost: false,
          savedThisPost: false,
          user: false,
          quizIntro: (res[0].acf.intro_text !== undefined) ? Parse(res[0].acf.intro_text) : false,
          quizButtonText: (res[0].acf.button_text !== undefined) ? Parse(res[0].acf.button_text) : false,
          activeSlide: 0,
          settings: {
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '40px',
            arrows : false,
            afterChange: current => this.setState({ activeSlide: current })
          },
          ogTitle: res[0].acf.title_og,
          ogDescription: res[0].acf.description_og,
          ogImage: res[0].acf.image_og ? res[0].acf.image_og.sizes.large : false,
          ogImageWidth: res[0].acf.image_og ? res[0].acf.image_og.sizes['large-width'] : false,
          ogImageHeight: res[0].acf.image_og ? res[0].acf.image_og.sizes['large-height'] : false,
        }),
      )
      .catch(err => console.log(err));
  }

  componentDidUpdate() {
    if ((this.props.location.pathname != this.state.postPath) ) {
      this.getWpPost()
      if (this.scrollToRef.current) {
        console.log('scroll');
        console.log(this.scrollToRef);
        this.scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (this.state.post.id != undefined && this.state.visited === false) {
      this.setState({ visited: true })
      this.registerPostVisit(this.state.post.id)
    }

    if (this.props.user.acf !== undefined && this.state.user === false) {
      this.setState({ user: this.props.user })
      if (this.props.user.acf.liked_posts && this.props.user.acf.liked_posts.length > 0) {
        var likedArrObj = JSON.parse(this.props.user.acf.liked_posts)
        var postId = this.state.post.id.toString()
        if (typeof(likedArrObj) === 'array') {
          this.setState({ likedThisPost: likedArrObj.includes(postId) })
        }
        if (typeof(likedArrObj) === 'object') {
          this.setState({ likedThisPost: Object.values(likedArrObj).includes(postId) })
        }
      }

      if (this.props.user.acf.saved_posts && this.props.user.acf.saved_posts.length > 0) {
        var savedArrObj = JSON.parse(this.props.user.acf.saved_posts)
        var postId = this.state.post.id.toString()
        if (typeof(savedArrObj) === 'array') {
          this.setState({ savedThisPost: savedArrObj.includes(postId) })
        }
        if (typeof(savedArrObj) === 'object') {
          this.setState({ savedThisPost: Object.values(savedArrObj).includes(postId) })
        }
      }
    }
  }
  handleNotPlaying() {
    this.setState({
      playing: false
    })
    var videoElem = this.videoPlayer.current.player.player.player

    this.setState({
      isFullscreen: false
    })
  }
  handleCloseVideo() {
    this.handleNotPlaying()
  }
  handleProgress = state => {
    if (!this.state.seeking) {
      this.setState(state)
    }
  }
  handleDuration = (duration) => {
    this.setState({ duration })
  }
  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }

  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.setState({ playing: false })
    this.videoPlayer.current.seekTo(parseFloat(e.target.value))
  }

  saveProgress(e) {
    if (this.state.postType === 'podcast') {

      if (this.audioPlayer.current !== null) {
        var postId = this.state.post.id
        var currentPodcasts = localStorage.nektar_podcasts
        if (currentPodcasts === undefined) {
          currentPodcasts = {}
        } else {
          currentPodcasts = JSON.parse(localStorage.nektar_podcasts)
        }
        var audioProgress = {}
        var audioProgress = {
          currentTime: this.audioPlayer.current.audio.current.currentTime,
          duration: this.audioPlayer.current.audio.current.duration,
          src: this.audioPlayer.current.audio.current.currentSrc,
          'postId': postId
        }
        Object.assign(currentPodcasts, {[postId]: audioProgress});

        localStorage.setItem('nektar_podcasts', JSON.stringify(currentPodcasts));
      }
    }
  }

  saveFinished(e) {
    if (this.state.postType === 'podcast') {
      var postId = this.state.post.id
      var finishedPodcasts = []
      if (localStorage.nektar_podcasts_finished !== undefined) {
        if (!localStorage.nektar_podcasts_finished.includes(postId)) {
          finishedPodcasts.push(JSON.parse(localStorage.nektar_podcasts_finished))
        }
      }

      finishedPodcasts.push(postId)
      if (this.audioPlayer.current !== null) {
        localStorage.setItem('nektar_podcasts_finished', finishedPodcasts);
      }
    }
  }

  getBack(e) {
    e.preventDefault()
    this.props.history.goBack()
  }

  handleSavePost(e) {
    if (this.props.loggedIn.length > 0) {
      const postId = this.state.post.id
      const userId = this.props.user.id
      const _this = this;
      const dataArray = new FormData();
      dataArray.append("postId", postId);
      dataArray.append("userId", userId);

      axios.post("/wp-json/wp/v2/save-user-post", dataArray, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(
        function(res) {
          _this.setState({
            postSaves: res.data,
            savedPost: !_this.state.savedPost
          })
          document.querySelector('button.stars').classList.toggle('active')
        }
      ).catch(err => console.log(err));
    }
  }

  handleLikePost(e) {
    if (this.props.loggedIn.length > 0) {
      const postId = this.state.post.id
      const userId = this.props.user.id
      const _this = this;
      const dataArray = new FormData();
      dataArray.append("postId", postId);
      dataArray.append("userId", userId);

      axios.post("/wp-json/wp/v2/like-post", dataArray, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(
        function(res) {
          _this.setState({
            postLikes: res.data,
            likedPost: !_this.state.likedPost
          })
          document.querySelector('button.bravo').classList.toggle('active')
        }
      ).catch(err => console.log(err));
    }
  }

  handleSharePost(e) {
    if (this.props.loggedIn.length > 0) {
      const postId = this.state.post.id
      const userId = this.props.user.id
      const _this = this;
      const dataArray = new FormData();
      dataArray.append("postId", postId);
      dataArray.append("userId", userId);

      axios.post("/wp-json/wp/v2/share-post", dataArray, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(
        function(res) {
          _this.setState({
            postShares: res.data,
          })
        }
      ).catch(err => console.log(err));
    }
  }

  openSharePost(e) {
    this.setState({
      shareOpen: !this.state.shareOpen,
    })
  }

  slidePrev() {
    this.slider.slickPrev();
  }

  slideNext() {
    this.slider.slickNext();
  }

  play(e) {
    e.preventDefault()
    if (this.state.postType === 'podcast') {
      this.state.playing ? this.audioPlayer.current.audio.current.pause() : this.audioPlayer.current.audio.current.play()

      this.setState({
        playing: !this.state.playing,
      })
    }
    if (this.state.postType === 'video') {

      var videoElem = this.videoPlayer.current.player.player.player
      this.setState({
        isFullscreen: true,
        playing: !this.state.playing
      })
    }
  }
  rewind(e) {
    document.querySelector('.rhap_rewind-button').click();
  }
  forward(e) {
    document.querySelector('.rhap_forward-button').click();
  }

  createVideoMarkup() {
    return {
     __html: 'test'
    };
  }

  render() {
    const {post, isLoaded, title, featuredImg, authors, caption, date, textInClrBlack, textInClrWhite, img1, img2, images, postType, subtitle, audiofile, playing, videofile, episode, played, duration, videoOrImage, topVideo } = this.state;
    const {postLikes, postShares, postSaves, shareOpen} = this.state;
    const {likedThisPost, savedThisPost} = this.state;
    const {ogTitle, ogDescription, ogImage, thumbnail, ogImageWidth, ogImageHeight, thumbnailWidth, thumbnailHeight} = this.state;
    const {loggedIn } = this.props;
    var lejrbaletCat = false

    if (isLoaded) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);
      if (postType === 'quiz') {
        return (
          <Quiz
            stateParent={this.state}
            history={this.props.history}
          />
        )
      }

      if (post._embedded['wp:term'][0].length > 0) {
        for (var i=0; post._embedded['wp:term'][0].length > i; i++) {
          if (post._embedded['wp:term'][0][i] && post._embedded['wp:term'][0][i].id === 17 ) {
            var lejrbaletCat = true
          }
        }
      }

      return (
        <>
          <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={ogTitle ? ogTitle : title} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={ogImage ? ogImage : thumbnail} />
            <meta property="og:image:width" content={ogImageWidth ? ogImageWidth : thumbnailWidth} />
            <meta property="og:image:height" content={ogImageHeight ? ogImageHeight : thumbnailHeight} />
            <meta property="og:description" content={ogDescription} />
          </Helmet>
          <div ref={this.scrollToRef} className={`inner-container ${postType} ${lejrbaletCat ? 'lejrbalet' : ''}`}>
            {postType === 'video' && videofile &&
              <div className={`player-wrapper video-wrapper ${playing ? "showing" : ""}`}>
                <button onClick={this.handleCloseVideo} className="close-vid">
                  <CloseIcon />
                </button>
                <ReactPlayer
                  playing={playing}
                  url={videofile.url}
                  controls={true}
                  width={'100%'}
                  height={'auto'}
                  // onPause={this.handleNotPlaying}
                  onEnded={this.handleNotPlaying}
                  ref={this.videoPlayer}
                  onProgress={this.handleProgress}
                  onDuration={this.handleDuration}
                  onSeek={e => console.log('onSeek', e)}
                  id='video'
                />

              </div>
            }
            {postType === 'video' && videofile && false &&
              <CSSTransition
                in={playing}
                timeout={100}
                classNames="content"
              >
                <p>test</p>
              </CSSTransition>
            }
            <div className="container" style={{backgroundColor:post.category_color}}>
              <section className={"single-post pop-up " + (postType === 'video' ? 'video-container ' : '' + (postType === 'podcast' ? 'podcast-container' : ''))}>
                {postType !== 'quiz' && !lejrbaletCat &&
                  <div className="top-left-corner-icon">
                    <TopLeftCorner />
                  </div>
                }
                {false &&
                  <a href="/" onClick={this.getBack} className="post-exit-button"><ArrowRight/></a>
                }
                {postType !== 'quiz' && !lejrbaletCat &&
                  <div className="top-left-corner">
                    <ul className="features-list">
                      <li className="messeges">
                        <MessagesIcon />
                        <span>{postShares}</span>
                      </li>
                      <li className="bravo">
                        <BravoIcon />
                        <span>{postLikes}</span>
                      </li>
                      <li className="stars">
                        <StarIcon />
                        <span>{postSaves}</span>
                      </li>
                    </ul>
                    <h4 style={{color:post.category_color}} className="subtitle">{post.acf.subheading_1}</h4>
                    {episode !== false &&
                      <div style={{color:post.category_color}} className="episode-number">{episode}</div>
                    }
                  </div>
                }
                <div className="background-cover-block">
                  {videoOrImage == 'image' &&
                    <img src={featuredImg} />
                  }
                  {videoOrImage == 'video' &&
                    <div className="video-cont" dangerouslySetInnerHTML={{ __html: `
                      <video muted autoPlay playsinline loop>
                        <source src="${topVideo.url}" type="${topVideo.mime_type}"/>
                        Your browser does not support the video tag.
                      </video>
                    `}}>
                    </div>
                  }
                  {postType === 'video' && videofile !== false &&
                    <>
                      <CSSTransition
                        in={!playing}
                        timeout={100}
                        classNames="content"
                        unmountOnExit
                      >
                        <div className="audio-controls">
                          <button onClick={this.play} className="play">
                            <PlayPause />
                            <CSSTransition
                              in={!playing}
                              timeout={50}
                              classNames="playing"
                              unmountOnExit
                            >
                              <span className="play-active"><Play /></span>
                            </CSSTransition>
                          </button>
                        </div>
                      </CSSTransition>
                    </>
                  }
                </div>
                {postType === 'video' &&
                  <div style={{backgroundColor:post.category_color}} className="text-block first-post-block">
                    <SvgColor color={post.category_color} selector={'div.post-top-wave > svg path'} />
                    <div className="post-top-wave">
                      <PostTextWave />
                    </div>

                    {loggedIn.length > 0 &&
                      <>
                        <div className="features-buttons">
                          <button onClick={this.openSharePost} className="messeges">
                            <MessagesIcon />
                          </button>
                          <button onClick={this.handleLikePost} className={`bravo ${likedThisPost ? "active" : ""}`}>
                            <BravoIcon />
                          </button>
                          <button onClick={this.handleSavePost} className={`stars ${savedThisPost ? "active" : ""}`}>
                            <StarIcon />
                          </button>
                        </div>
                        <SwitchTransition mode={'out-in'}>
                          <CSSTransition
                            key={shareOpen}
                            addEndListener={(node, done) => {
                              node.addEventListener("transitionend", done, false);
                            }}
                            classNames="content"
                          >
                            <>
                              {shareOpen &&
                                <div className={'share-container '+(this.shareOpen ? 'active' : '')}>
                                  <div className={'share-buttons'}>
                                    <EmailShareButton
                                      url={window.location.href}
                                      subject={title}
                                      body={window.location.href}
                                      className="Demo__some-network__share-button"
                                     >
                                      <EmailIcon onClick={this.handleSharePost} size={32} round={true} />
                                    </EmailShareButton>
                                    <FacebookShareButton
                                      url={window.location.href}
                                      quote={title}
                                      onClick={this.handleSharePost}
                                    >
                                      <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <LinkedinShareButton
                                      url={window.location.href}
                                      onClick={this.handleSharePost}
                                    >
                                      <LinkedinIcon  size={32} round />
                                    </LinkedinShareButton>
                                    <TwitterShareButton
                                      url={window.location.href}
                                      title={title}
                                      onClick={this.handleSharePost}
                                    >
                                      <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                  </div>
                                </div>
                              }
                          </>
                        </CSSTransition>
                      </SwitchTransition>
                      </>
                    }
                    <div className="transform-top">
                      <div className="post-info">
                        <div className="upload-date">{date}<span className="border">|</span></div>
                        <div className="created-by">Af <span className="color-dark">
                          {authors !== false && authors.map((author, i) => {
                            if (i > 0) {
                              return (
                                <Link
                                  to={{
                                    pathname: '/redaktion/'+author.user_nicename,
                                  }}
                                >
                                   og {author.display_name}
                                </Link>
                              )
                            }
                            return (
                              <Link
                                to={{
                                  pathname: '/redaktion/'+author.user_nicename,
                                }}
                              >
                                {author.display_name+' '}
                              </Link>
                            )
                           })}
                           </span>{caption && <span className="border">|</span>}</div>
                         {caption &&
                           <>
                             <div className="photos-from">Foto fra <span className="color-dark">{caption}</span></div>
                           </>
                         }
                      </div>
                      <div className="categories-list">
                        <Taxonomies postObj={post} tax={0} style={post.category_color} className={'category-tag background-white'} />
                        <Taxonomies postObj={post} tax={2} style={false} className={'category-theme background-dark color-white'} />
                      </div>
                      <h1 className="text-block-title">{title}</h1>
                      <div className="rhap_container">
                        <div className="rhap_progress-section">
                          <div id="rhap_current-time" className="rhap_time rhap_current-time color-dark">
                            <Duration seconds={duration * played} />
                          </div>
                          <input
                            className="vid-seeker"
                            type='range' min={0} max={0.999999} step='any'
                            value={played}
                            onMouseDown={this.handleSeekMouseDown}
                            onMouseUp={this.handleSeekMouseUp}
                            onTouchStart={this.handleSeekMouseDown}
                            onTouchEnd={this.handleSeekMouseUp}
                            onChange={this.handleSeekChange}
                          />
                          <progress max={1} value={played} />
                          <Duration seconds={duration} />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {postType !== 'video' &&
                  <div style={{backgroundColor:post.category_color}} className="text-block first-post-block color-white">
                    <SvgColor color={post.category_color} selector={'div.post-top-wave > svg path'} />
                    <div className="post-top-wave">
                      <>
                        <PostTextWave />
                        {postType === 'quiz' &&
                          <CSSTransition in={this.state.quizStarted && !this.state.quizFinished} unmountOnExit="true"  timeout={200} classNames="alert">
                            <div className="current-question">
                              <PlayPause />
                              <div>
                                {this.state.currentQuizQuestion+1} / {post.acf.quiz_builder.length}
                              </div>
                            </div>
                          </CSSTransition>
                        }
                      </>
                    </div>

                    {loggedIn.length > 0 && postType !== 'quiz' && !lejrbaletCat &&
                      <>
                        <div className="features-buttons">
                          <button onClick={this.openSharePost} className="messeges">
                            <MessagesIcon />
                          </button>
                          <button onClick={this.handleLikePost} className={`bravo ${likedThisPost ? "active" : ""}`}>
                            <BravoIcon />
                          </button>
                          <button onClick={this.handleSavePost} className={`stars ${savedThisPost ? "active" : ""}`}>
                            <StarIcon />
                          </button>
                        </div>
                        <SwitchTransition mode={'out-in'}>
                          <CSSTransition
                            key={shareOpen}
                            addEndListener={(node, done) => {
                              node.addEventListener("transitionend", done, false);
                            }}
                            classNames="content"
                          >
                            <>
                              {shareOpen &&
                                <div className={'share-container '+(this.shareOpen ? 'active' : '')}>
                                  <div className={'share-buttons'}>
                                    <EmailShareButton
                                      url={window.location.href}
                                      subject={title}
                                      body={window.location.href}
                                      className="Demo__some-network__share-button"
                                     >
                                      <EmailIcon onClick={this.handleSharePost} size={32} round={true} />
                                    </EmailShareButton>
                                    <FacebookShareButton
                                      url={window.location.href}
                                      quote={title}
                                      onClick={this.handleSharePost}
                                    >
                                      <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <LinkedinShareButton
                                      url={window.location.href}
                                      onClick={this.handleSharePost}
                                    >
                                      <LinkedinIcon  size={32} round />
                                    </LinkedinShareButton>
                                    <TwitterShareButton
                                      url={window.location.href}
                                      title={title}
                                      className="Demo__some-network__share-button"
                                      onClick={this.handleSharePost}
                                    >
                                      <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                  </div>
                                </div>
                              }
                            </>
                          </CSSTransition>
                        </SwitchTransition>
                      </>
                    }
                    <div className="transform-top">
                      {postType !== 'quiz' &&
                        <>
                          <div className="post-info">
                            <div className="upload-date">{date}<span className="border">|</span></div>
                            <div className="created-by">Af <span className="color-dark">
                              {authors !== false && authors.map((author, i) => {
                                if (i > 0) {
                                  return (
                                    <Link
                                      key={i}
                                      to={{
                                        pathname: '/redaktion/'+author.user_nicename,
                                      }}
                                    >
                                       og {author.display_name}
                                    </Link>
                                  )
                                }
                                return (
                                  <Link
                                    key={i}
                                    to={{
                                      pathname: '/redaktion/'+author.user_nicename,
                                    }}
                                  >
                                    {author.display_name+' '}
                                  </Link>
                                )
                               })}
                             </span>{caption && <span className="border">|</span>}</div>
                            {caption &&
                              <>
                                <div className="photos-from">Foto fra <span className="color-dark">{caption}</span></div>
                              </>
                            }
                          </div>
                          <div className="categories-list">
                            <Taxonomies postObj={post} tax={0} style={post.category_color} className={'category-tag background-white'} />
                            <Taxonomies postObj={post} tax={2} style={false} className={'category-theme background-dark color-white'} />
                          </div>
                          <h1 className="text-block-title">{title}</h1>
                          {subtitle &&
                            <span className="subtitle color-white">{subtitle}</span>
                          }
                        </>
                      }
                      {audiofile && postType === 'podcast' &&
                        <>
                          <div className="audio-controls">
                            <button onClick={this.rewind}><Prev /></button>
                            <button onClick={this.play} className="play">
                              <PlayPause />
                              <CSSTransition
                                in={!playing}
                                timeout={50}
                                classNames="playing"
                                unmountOnExit
                              >
                                <span className="play-active"><Play /></span>
                              </CSSTransition>
                              <CSSTransition
                                in={playing}
                                timeout={50}
                                classNames="playing"
                                unmountOnExit
                              >
                                <span className="play-active"><Pause /></span>
                              </CSSTransition>
                            </button>
                            <button onClick={this.forward}><Next /></button>
                          </div>
                          <AudioPlayer
                            autoPlay={false}
                            progressJumpStep={15000}
                            src={audiofile.url}
                            customAdditionalControls={[]}
                            customVolumeControls={[]}
                            ref={this.audioPlayer}
                            onListen={this.saveProgress}
                            onEnded={this.saveFinished}
                            defaultCurrentTime={'00:33'}
                          />
                        </>
                      }
                      {textInClrBlack !== false && postType === 'text' && textInClrBlack}
                    </div>
                  </div>
                }
                {postType === 'text' && post.acf.text_content.images && images.length < 3 &&
                  <div className="text-block-images" style={{background: 'linear-gradient(to bottom,'+post.category_color+' 17.4rem,#fff 20%)'}}>
                    {post.acf.text_content.images !== false && post.acf.text_content.images.map((image, i) => {
                      return (
                        <img
                          key={i}
                          className={`
                            ${images.length === 2 && i === 0 ? 'first-image' : ''}
                            ${images.length === 2 && i === 1 ? 'second-image' : ''}
                            ${images.length === 1 ? 'first-image single-image' : ''}
                          `}
                          srcSet={`${image.sizes.medium} 300w, ${image.sizes.large} 768w, ${image.url} 1280w`} src={image.sizes.medium}
                        />
                      )
                    })}
                  </div>
                }
                {postType === 'text' && post.acf.text_content.images && images.length > 2 &&
                  <div className="text-block-images" style={{background: 'linear-gradient(to bottom,'+post.category_color+' 17.4rem,#fff 20%)'}}>
                    <SliderSlick ref={c => (this.slider = c)} {...this.state.settings}>
                      {images.map((image, i) => {
                        return (
                          <img
                            key={i}
                            className={`
                              ${'first-image single-image'}
                            `}
                            srcSet={`${image.sizes.medium} 300w, ${image.sizes.large} 768w, ${image.url} 1280w`} src={image.sizes.medium}
                          />
                        )
                      })}
                    </SliderSlick>
                    <div className="slick-custom-nav">
                      <button onClick={this.slidePrev}><ArrowRight /></button>
                      <div className="current">{this.state.activeSlide+1} / {images.length}</div>
                      <button onClick={this.slideNext}><ArrowRight /></button>
                    </div>
                  </div>
                }
                {textInClrWhite !== false && postType === 'text' &&
                  <div className="text-block background-white">
                    {textInClrWhite}
                  </div>
                }
                {lejrbaletCat &&
                  <section className="custom-title outside-icon">
                    Prøv flere <span style={{color:post.category_color}}>quizzer og test</span>
                    <div className="custom-title-icon">
                      <ArrowDown />
                    </div>
                  </section>
                }
                {post.similarPosts && postType === 'text' &&
                  <PostListSlider slider={post.similarPosts} />
                }
              </section>
            </div>
          </div>
        </>
      )
    }
    return (
      ''
    )
  }
}


function Image(props) {
  const acf = props.acf;
  const img = props.image;
  try {
    if (props.acf.[img] !== false) {
      if (img === 'image_1') {
        return <img className="first-image" srcSet={`${props.acf.[img].sizes.medium} 300w, ${props.acf.[img].sizes.large} 768w, ${props.acf.[img].url} 1280w`} src={props.acf.[img].sizes.medium} />
      }
      if (img === 'image_2') {
        let imgClass = 'second-image'
        if (props.acf.image_1 === false) {
          let imgClass = 'first-image'
        }
        return <img className={imgClass} srcSet={`${props.acf.[img].sizes.medium} 300w, ${props.acf.[img].sizes.large} 768w, ${props.acf.[img].url} 1280w`} src={props.acf.[img].sizes.medium} />
      }
    }
  } catch (error) {
    console.log(error);
  }
  return ''
}

function Taxonomies(props) {
  var post = props.postObj
  var tax = props.tax
  var ret = '';
  var taxClass = props.className

  for (var i=0; post._embedded['wp:term'][tax].length > i; i++) {
    var taxTerm = post._embedded['wp:term'][tax][i]

    var linkType = '/kategori/'
    var style = ''
    if (props.style !== false) {
      var style = 'style="color:'+props.style+'"'
    }
    if (taxTerm.taxonomy === 'post_tag') {
      var linkType = '/tema/'
    }
    if (taxTerm.name !== 'Uncategorized') {
      ret = '<a '+style+' href="'+linkType+taxTerm.slug+'" class="'+taxClass+'">'+taxTerm.name+'</a>'
    }
  }

  return Parse(ret)
}

export default PostPage

function SvgColor(props) {
  return (
    Parse("<style>"+props.selector+" {fill: "+props.color+"!important;}</style>")
  )
}
