import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import '../styles/post-list.css'
import '../styles/homepage.css'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import PostListSlider from '../components/PostListSlider';
import SearchResultLlist from '../components/SearchResultLlist';
import TopicsSlider from '../components/TopicsSlider';
import {Helmet} from "react-helmet";

export class MostVisited extends React.Component {
  state = {
    posts: [],
    popular: [],
    isLoaded: false
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    wp.latestResource = wp.registerRoute( 'wp/v2', 'get_weekely_popular' );

    wp.latestResource().get()
    .then(res => this.setState({
      posts: res.posts,
      isLoaded: true
    }))
    .catch(err => console.log(err));

  }

  render() {
    const {posts, isLoaded} = this.state;
    setTimeout(function(){ document.body.classList.remove('loading'); },500);

    if (isLoaded) {
      return (
        <div className="inner-container">
          <Helmet>
              <title>{'Ugens mest besøgte'}</title>
          </Helmet>
          <div className="samling-top background-cacoa">
            <SearchResultsWave />
          </div>
          <div className="samling-result top-content">
            <h1 className="color-dark">ugens mest besøgte</h1>
          </div>

          <ul className="search-result-list">
            <SearchResultLlist posts={posts} />
          </ul>
        </div>
      );
    }
    return ''
  }
}

export default MostVisited
