import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { ReactComponent as GlassesIcon } from '../assets/svg/glasses.svg';
import { ReactComponent as AudioIcon } from '../assets/svg/earphones.svg';
import { ReactComponent as VideoIcon } from '../assets/svg/video.svg';
import { ReactComponent as TopicWave } from '../assets/svg/theme-slider-wave.svg';
import Parse from 'html-react-parser';

export class CategoriesSlider extends React.Component {

  render() {
    return (
      <>
        {this.props.categories.map((category, i) => {
          return (
            <Fragment key={i}>
              <li>
                <Link
                  to={{
                    pathname: '/kategori/'+category.slug,
                    state: {
                      showAll: true,
                    }
                  }}
                >
              		<div style={{backgroundColor:category.category_color}} className="lean-background"></div>
              		<h3 className="color-white">{category.name}</h3>
              		<span className="category-subtitle color-dark">Kategori<span className="border">|</span><span>{category.count} indlæg</span></span>
                </Link>
              	<div className="theme-post-types">
                  <Link
                    to={{
                      pathname: '/kategori/'+category.slug,
                      state: {
                        selectedType: 'video',
                        showAll: false,
                        showPodcast: false,
                        showText: false,
                        showVideo: true,
                      }
                    }}
                    className={`button background-dark color-white ${category.countVideos < 1 ? 'disabled' : ''}`}
                  >
                    <VideoIcon />
                    {category.countVideos} videoer
                  </Link>
                  <Link
                    to={{
                      pathname: '/kategori/'+category.slug,
                      state: {
                        selectedType: 'podcast',
                        showAll: false,
                        showPodcast: true,
                        showText: false,
                        showVideo: false,
                      }
                    }}
                    className={`button background-dark color-white ${category.countAudio < 1 ? 'disabled' : ''}`}
                  >
                    <AudioIcon />
                    {category.countAudio} podcast
                  </Link>
                  <Link
                    to={{
                      pathname: '/kategori/'+category.slug,
                      state: {
                        selectedType: 'text',
                        showAll: false,
                        showPodcast: false,
                        showText: true,
                        showVideo: false,
                      }
                    }}
                    className={`button background-dark color-white ${category.countTexts < 1 ? 'disabled' : ''}`}
                  >
                    <GlassesIcon />
                    {category.countTexts} artikler
                  </Link>
              	</div>
                <Link
                  to={{
                    pathname: '/kategori/'+category.slug,
                    state: {
                      showAll: true,
                    }
                  }}
                >
                  <p className="category-short-description color-white">{category.description}</p>
                </Link>
              </li>
            </Fragment>
          )
        })}
      </>
    );
  }
}

export default CategoriesSlider
