import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import { useHistory } from 'react-router-dom'
import '../styles/post-list.css'
import '../styles/homepage.css'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as HeroWave } from '../assets/svg/top-banner-wave.svg';
import { ReactComponent as GlassesIcon } from '../assets/svg/glasses.svg';
import { ReactComponent as AudioIcon } from '../assets/svg/earphones.svg';
import { ReactComponent as VideoIcon } from '../assets/svg/video.svg';
import { ReactComponent as TopicSliderWave } from '../assets/svg/theme-slider-wave.svg';
import PostListSlider from '../components/PostListSlider';
import TopicsSlider from '../components/TopicsSlider';
import { CSSTransition } from 'react-transition-group';
import SearchResultLlist from '../components/SearchResultLlist';
import Parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

class TopicPage extends Component {
  state = {
    category: {},
    isLoaded: false,
    catPath: false,
    selectedType: false,
    showAll: true,
    showPodcast: false,
    showText: false,
    showVideo: false,
    showingMore: false
  }

  constructor(props) {
    super(props);
    this.switchType = this.switchType.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== this.state.catPath) {
      window.scrollTo(0, 0)
      this.getTopic()
      if (this.props.match.params.slug !== prevProps.match.params.slug) {
        this.setSelectedType()
      }
    }
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    this.getTopic()
    this.setSelectedType()
  }

  setSelectedType() {
    if (this.props.location.state !== undefined && this.props.location.state.show !== undefined) {
      switch (this.props.location.state.show) {
        case 'videos':
          this.setState({
            selectedType: 'video',
            showAll: false,
            showPodcast: false,
            showText: false,
            showVideo: true,
          })
          break;
        case 'texts':
          this.setState({
            selectedType: 'text',
            showAll: false,
            showPodcast: false,
            showText: true,
            showVideo: false,
          })
          break;
        case 'podcasts':
          this.setState({
            selectedType: 'podcast',
            showAll: false,
            showPodcast: true,
            showText: false,
            showVideo: false,
          })
          break;
      }
    }
  }

  handleShowMore(e) {
    e.preventDefault()
    this.setState({
      showingMore: !this.state.showingMore,
    })
  }

  switchType(e) {
    e.preventDefault()
    if (this.state.selectedType === e.target.dataset.type) {
      this.setState({
        selectedType: false,
        showAll: true,
        showPodcast: false,
        showText: false,
        showVideo: false,
      })
    } else {
      if (e.target.dataset.type === 'video') {
        this.setState({
          showAll: false,
          showPodcast: false,
          showText: false,
          showVideo: true,
          selectedType: e.target.dataset.type,
        })
      }
      if (e.target.dataset.type === 'podcast') {
        this.setState({
          showAll: false,
          showPodcast: true,
          showText: false,
          showVideo: false,
          selectedType: e.target.dataset.type,
        })
      }
      if (e.target.dataset.type === 'text') {
        this.setState({
          showAll: false,
          showPodcast: false,
          showText: true,
          showVideo: false,
          selectedType: e.target.dataset.type,
        })
      }
    }
  }

  getTopic() {
    wp.getTopicResource = wp.registerRoute( 'wp/v2', 'get-taxonomy/(?P<slug>)',
      {
        params: ['type']
      }
    );
    var catSlug = this.props.match.params.slug
    wp.getTopicResource().slug(catSlug).type('topic').get()
      .then(
        res => this.setState({
          category: res,
          isLoaded: true,
          catPath: this.props.location.pathname
        })
    )
      .catch(err => console.log(err));
  }

  render() {
    const {category, isLoaded, selectedType, showAll, showPodcast, showVideo, showText, showingMore } = this.state;
    if (isLoaded) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);

      if (
        category.categoryPodcasts.length === 0 &&
        category.categoryVideos.length === 0 &&
        category.categoryTexts.length === 0
      ) {
        // this.props.history.push('/')
      }
      return (
        <div className="inner-container page">
          <Helmet>
              <title>{category.title}</title>
          </Helmet>
          <section className="theme-single">
            <InlineCss value={'linear-gradient(to bottom,'+category.category_color+' 14.1rem,transparent 20%)'} rule={'background'} selector={'.first-post-block:after'} />
            <div className="background-cover-block">
              <img src={category.image.url} srcSet={category.image.srcset} />
            </div>

            <div style={{backgroundColor:category.category_color}} className="text-block first-post-block">
              <div className="post-top-wave">
                <SvgColor color={category.category_color} selector={'.post-top-wave svg path.a'} />
                <TopicSliderWave />
              </div>
              <div style={{backgroundColor:category.category_color}} className="transform-top category-top">
              	<div className="top-content color-white">
              		<h1 className="color-white">{category.title}</h1>
              		<span className='color-dark'>Tema</span>
              		<div className="theme-post-types">
                    <a href="/" onClick={this.switchType} data-type="video" className={`button background-dark color-white ${showVideo === true ? 'active' : ''} ${category.categoryVideos.length < 1 ? 'disabled' : ''}`}><VideoIcon />{category.categoryVideos.length} videoer</a>
                    <a href="/" onClick={this.switchType} data-type="podcast" className={`button background-dark color-white ${showPodcast === true ? 'active' : ''} ${category.categoryPodcasts.length < 1 ? 'disabled' : ''}`}><AudioIcon />{category.categoryPodcasts.length} podcast</a>
                    <a href="/" onClick={this.switchType} data-type="text" className={`button background-dark color-white ${showText === true ? 'active' : ''} ${category.categoryTexts.length < 1 ? 'disabled' : ''}`}><GlassesIcon />{category.categoryTexts.length} artikler</a>
              		</div>
                  <h4 className="color-white">{category.subheading}</h4>
                  {Parse(category.intro_text)}
              	</div>
              </div>
              <CSSTransition
                in={showingMore}
                timeout={100}
                classNames="content"
                unmountOnExit
              >
                <div className="theme-content color-white">
                  {Parse(category.main_text)}
                  <Link
                    className="theme-author"
                    to={{
                      pathname: '/redaktion/'+category.author.user_nicename,
                    }}
                  >
                    <div className="profile-image">
                    {category.author_pic !== null &&
                      <img src={category.author_pic.sizes.thumbnail} />
                    }
                    </div>
                    <h3 className="color-dark name-title">{category.author.display_name}</h3>
                    <p className="author-position color-dark">Skribent</p>
                    <ul className="author-posts">
                      <li><VideoIcon /><span>{category.author_videos ? category.author_videos : '0'}</span></li>
                      <li><AudioIcon /><span>{category.author_podcasts ? category.author_podcasts : '0'}</span></li>
                      <li><GlassesIcon /><span>{category.author_texts ? category.author_texts : '0'}</span></li>
                    </ul>
                  </Link>
                </div>
              </CSSTransition>
              {this.state.showingMore === false &&
                <a href="/" onClick={this.handleShowMore} className="read-more button closed color-white"><ArrowRight /><span>Læs hele lederen</span></a>
              }
              {this.state.showingMore &&
                <a href="/" onClick={this.handleShowMore} className="read-more button opened color-white"><ArrowRight /><span>Luk lederen</span></a>
              }
            </div>
            <div className="page-content">
              <CSSTransition
                in={showAll}
                timeout={100}
                classNames="content"
                unmountOnExit
              >
                <>
                  {category.sliders.map((slider, i) => {
                    if (slider.type !== 'topics') {
                      return (
                        <PostListSlider key={i} slider={slider} />
                      )
                    }
                   })}
                   {category.sliders.length < 1 &&
                     <SearchResultLlist posts={category.categoryAllPosts} />
                   }
                  <section className="custom-title outside-icon">
                    Udforsk <span style={{color:category.category_color}}>nektars</span> andre temaer
                    <div className="custom-title-icon">
                      <ArrowDown />
                    </div>
                  </section>
                  <TopicsSlider topics={category.otherCats} />
                </>
              </CSSTransition>
              <CSSTransition
                in={showPodcast}
                timeout={100}
                classNames="content"
                unmountOnExit
              >
                <>
                  <SearchResultLlist posts={category.categoryPodcasts} />
                  <PostListSlider slider={category.podcastSeries} />
                  <section className="custom-title outside-icon">
                    Udforsk <span style={{color:category.category_color}}>nektars</span> temaer
                    <div className="custom-title-icon">
                      <ArrowDown />
                    </div>
                  </section>
                  <TopicsSlider topics={category.otherCats} />
                </>
              </CSSTransition>
              <CSSTransition
                in={showVideo}
                timeout={100}
                classNames="content"
                unmountOnExit
              >
                <>
                  <SearchResultLlist posts={category.categoryVideos} />
                  <PostListSlider slider={category.videoSeries} />
                  <section className="custom-title outside-icon">
                    Udforsk <span style={{color:category.category_color}}>nektars</span> temaer
                    <div className="custom-title-icon">
                      <ArrowDown />
                    </div>
                  </section>
                  <TopicsSlider topics={category.otherCats} />
                </>
              </CSSTransition>
              <CSSTransition
                in={showText}
                timeout={100}
                classNames="content"
                unmountOnExit
              >
                <>
                  <SearchResultLlist posts={category.categoryTexts} />
                  <PostListSlider slider={category.textSeries} />
                  <section className="custom-title outside-icon">
                    Udforsk <span style={{color:category.category_color}}>nektars</span> temaer
                    <div className="custom-title-icon">
                      <ArrowDown />
                    </div>
                  </section>
                  <TopicsSlider topics={category.otherCats} />
                </>
              </CSSTransition>
            </div>
          </section>
        </div>
      )
    }

    return ''
  }

}

export default TopicPage

function InlineCss(props) {
  return (
    Parse("<style>"+props.selector+"{"+props.rule+": "+props.value+";}</style>")
  )
}

function SvgColor(props) {
  return (
    Parse("<style>"+props.selector+"{fill: "+props.color+";}</style>")
  )
}
