import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { ReactComponent as GlassesIcon } from '../assets/svg/glasses.svg';
import { ReactComponent as AudioIcon } from '../assets/svg/earphones.svg';
import { ReactComponent as VideoIcon } from '../assets/svg/video.svg';
import { ReactComponent as TopicWave } from '../assets/svg/theme-slider-wave.svg';
import Parse from 'html-react-parser';
import VisibilitySensor from 'react-visibility-sensor';

export class TopicsSlider extends React.Component {
  state = {
    visibleSlider: false,
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange (isVisible) {
    if (isVisible) {
      this.setState({
        visibleSlider: true
      })
    } else {
      // setVisibleSlider(false)
    }
  }

  render() {
    return (
      <section className="themes-list-slider">
      	<ul>
          {this.props.topics.map((topic, i) => {
            return (
               <Fragment key={i}>
                <SvgColor color={topic.color} selector={topic.slug} />
                  <li className={`${topic.slug+'-topic'} ${this.state.visibleSlider ? 'is-visible' : ''}`}>
                    <VisibilitySensor onChange={this.onChange}>
                    <div className="inside-container">
                      <Link
                        to={{
                          pathname: '/tema/'+topic.slug,
                          state: {
                            show: 'showAll',
                          }
                        }}
                       >
                        <img src={topic.image} />
                      </Link>
                        <div style={{backgroundColor:topic.color}} className="themes-list-content">
                        <Link
                          to={{
                            pathname: '/tema/'+topic.slug,
                            state: {
                              show: 'showAll',
                            }
                          }} className="color-dark">
                            <div className="themes-list-top-vawe">
                              <TopicWave />
                            </div>
                            <h3 className="color-white">{topic.name}</h3>
                            <div className="post-count">
                              <span className="title">TEMA | </span><span>{topic.total}</span> indleag
                            </div>
                          </Link>
                          <div className="theme-post-types">
                            <Link
                              to={{
                                pathname: '/tema/'+topic.slug,
                                state: {
                                  show: 'videos',
                                }
                              }}
                              className={`button background-dark color-white ${topic.videos < 1 ? 'disabled' : ''}`}><VideoIcon />{topic.videos} videoer</Link>
                            <Link
                              to={{
                                pathname: '/tema/'+topic.slug,
                                state: {
                                  show: 'podcasts',
                                }
                              }}
                              className={`button background-dark color-white ${topic.podcasts < 1 ? 'disabled' : ''}`}><AudioIcon />{topic.podcasts} podcast</Link>
                            <Link
                              to={{
                                pathname: '/tema/'+topic.slug,
                                state: {
                                  show: 'texts',
                                }
                              }}
                              className={`button background-dark color-white ${topic.texts < 1 ? 'disabled' : ''}`}><GlassesIcon />{topic.texts} artikler</Link>
                          </div>
                        </div>
                      </div>
                    </VisibilitySensor>
                  </li>
                </Fragment>
            )
          })}

      	</ul>
      </section>
    );
  }
}

export default TopicsSlider

function SvgColor(props) {
  return (
    Parse("<style>."+props.selector+"-topic svg path.a {fill: "+props.color+";}</style>")
  )
}
