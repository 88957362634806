import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import { getAllPostsFromServer } from '../lib/utils';
import '../styles/post-list.css'
import '../styles/homepage.css'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as HeroWave } from '../assets/svg/top-banner-wave.svg';
import PostListSlider from '../components/PostListSlider';
import TopicsSlider from '../components/TopicsSlider';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import LandingPage from '../components/LandingPage';
import {Helmet} from "react-helmet";

export class Homepage extends React.Component {
  state = {
    posts: [],
    visibleSlider: false,
    isLoaded: false,
    landing: false
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('loading');
    wp.homeResource = wp.registerRoute( 'wp/v2', 'home' );

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|facebook|googlebot|google|linkedin|twitterbot|twitter/i.test(navigator.userAgent) || navigator.userAgent == 'ReactSnap'){
      wp.homeResource = wp.registerRoute( 'wp/v2', 'home' );

      wp.homeResource().get()
        .then(res => this.setState({
          home: res,
          isLoaded: true,
          landing: false
        }))
        .catch(err => console.log(err));
    } else {
      // document.hmtl.classList.add('landing');
      var root = document.getElementsByTagName( 'html' )[0];
      root.setAttribute( 'class', 'landing' );

      wp.homeResource = wp.registerRoute( 'wp/v2', 'landing' );

      wp.homeResource().get()
        .then(res => this.setState({
          landing: true,
          home: res,
          isLoaded: true
        }))
        .catch(err => console.log(err));
    }
  }

  onChange (isVisible) {
    if (isVisible) {
      this.setState({
        visibleSlider: true
      })
    } else {
      // setVisibleSlider(false)
    }
  }

  render() {
    const {home, isLoaded, landing} = this.state;

    if (isLoaded && landing) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);

      return (
        <>
          <Helmet>
              <title>{'Nektar'}</title>
              <meta property="og:title" content={home.title_og ? home.title_og : 'Nektar'} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:image" content={home.image_og ? home.image_og.sizes.large : ''} />
              <meta property="og:image:width" content={home.image_og ? home.image_og.sizes['large-width'] : ''} />
              <meta property="og:image:height" content={home.image_og ? home.image_og.sizes['large-height'] : ''} />
              <meta property="og:description" content={home.description_og != null ? home.description_og : ''} />
          </Helmet>
          <LandingPage page={home} />
        </>
      )
    }

    if (isLoaded && !landing) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);

      return (
        <div className="inner-container">
          <Helmet>
              <title>{'Nektar'}</title>
              <meta property="og:title" content={home.hero.title_og ? home.hero.title_og : 'Nektar'} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:image" content={home.hero.image_og ? home.hero.image_og.sizes.large : home.hero.image.src} />
              <meta property="og:image:width" content={home.hero.image_og ? home.hero.image_og.sizes['large-width'] : home.hero.image.width} />
              <meta property="og:image:height" content={home.hero.image_og ? home.hero.image_og.sizes['large-height'] : home.hero.image.height} />
              <meta property="og:description" content={home.hero.description_og != null ? home.hero.description_og : ''} />
          </Helmet>
          <section className="top-banner">
            <img src={home.hero.image.src} srcSet={home.hero.image.srcset} alt="" />
            <div className="top-banner-content">
              <div className="terms-container">
                {home.hero.topics && home.hero.topics.map((topic, i) => {
                  return (
                    <div key={i} className="top-banner-subtitle background-dark color-white">{topic.name}</div>
                  )
                })}
                {home.hero.cats && home.hero.cats.map((cat, i) => {
                  return (
                    <div key={i} className="top-banner-subtitle background-dark color-white">{cat.name}</div>
                  )
                })}
              </div>
              <h1>{home.hero.title}</h1>
              <p className="short-text">{home.hero.subtitle}</p>
              <Link
               to={{
                 pathname: home.hero.button_link,
               }}
               className="button background-white arrow-right">{home.hero.button_text} <ArrowRight /></Link>
              <VisibilitySensor onChange={this.onChange}>
                <div className={`category-tags-list ${this.state.visibleSlider ? 'is-visible' : ''}`}>
                  {home.categories.map((category, i) => {
                      return (
                        <Link
                          key={i}
                          className='category-tag-element-button'
                          style={{backgroundColor:category.color}}
                          to={{
                            pathname: '/kategori/'+category.slug,
                          }}
                        >
                          {category.name}
                        </Link>
                      )
                   })}
                 </div>
               </VisibilitySensor>
            </div>
            <div className="top-banner-bottom-wave">
              <HeroWave />
            </div>
          </section>
          <div className="page-content">
            {home.sliders.map((slider, i) => {
              if (slider.type !== 'topics') {
                return (
                  <PostListSlider key={i} slider={slider} />
                )
              } else {
                return (
                  <Fragment key={i}>
                    <section className="custom-title">
                      Udforsk <span className="color-light-purple">nektars</span> temaer
                      <div className="custom-title-icon">
                        <ArrowDown />
                      </div>
                    </section>
                    <TopicsSlider topics={slider.content} />
                  </Fragment>
                )
              }
             })}
          </div>
        </div>
      );
    }
    return ''
  }
}

export default Homepage
