import React, { Component, Fragment, useState, useEffect, useParams } from 'react'
import { ReactComponent as FilterWave } from '../assets/svg/filter-wave.svg';
import { ReactComponent as FilterIcon } from '../assets/svg/filter-icon.svg';
import { ReactComponent as FilterCloseIcon } from '../assets/svg/filter-close.svg';
import { ReactComponent as SearchIcon } from '../assets/svg/search-glass.svg';
import { ReactComponent as CheckIcon } from '../assets/svg/check-icon.svg';
import { ReactComponent as ResultsWave } from '../assets/svg/search-result-wave.svg';
import { useLocation, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';


const customStyles = {
  content : {
    height                : '100%',
    width                 : '100%',
    padding               : 0,
    border                : 'none',
    position: 'relative',
    transform: 'none',
    inset: 'unset',
    bottom: 0,
    overflowY: 'scroll',
    marginRight: 0,
    top: '0',
    left: '0',

  },
  overlay: {
    zIndex                : '999999',
    border                : 'none',
    bottom: 0,
    top: 0,
    left: 0,
    height: '100vh',
    background  : '#202844'
  },

};

Modal.setAppElement('#root')

const Filter = (props) => {
    const [modalIsOpen,setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedCats, setSelectedCats] = useState( [] );
    const [selectedTopics, setSelectedTopics] = useState( [] );
    const location = useLocation()
    const history = useHistory()
    const topics = props.topics
    const categories = props.categories

  	const [hasSelections,setHasSelections] = useState(false);
  	const [filterWaveHeight,setFilterWaveHeight] = useState(false);

    useEffect(() => {
    });

    function openModal() {
      setIsOpen(true);
    }

    function afterOpenModal() {
    }

    function closeModal(){
      setIsOpen(false);
    }

    function doSearch() {
    }

    function handleInputChange(e) {
      setInputValue(e.target.value)
    }

    function doFilter(e) {
      e.preventDefault()
      if (selectedTopics.length == 1 && selectedCats.length < 1 && inputValue.length < 1) {
        history.push('/tema/'+selectedTopics[0])
        closeModal()
        return
      }
      if (selectedCats.length == 1 && selectedTopics.length < 1 && inputValue.length < 1) {
        history.push('/kategori/'+selectedCats[0])
        closeModal()
        return
      }

      if (
        selectedCats.length > 1 ||
        selectedTopics.length > 1 ||
        (selectedCats.length > 0 && selectedTopics.length > 0) ||
        (selectedTopics.length > 0 && inputValue.length > 0) ||
        (selectedCats.length > 0 && inputValue.length > 0)
      ) {

        var query = ''
        if (inputValue !== false) {
          query = 'text='+inputValue
        }

        if (selectedCats.length > 0) {
          query += '&cat='
          selectedCats.map((category, i) => {
            query += category+','
          })
        }

        if (selectedTopics.length > 0) {
          query += '&topics='
          selectedTopics.map((topic, i) => {
            query += topic+','
          })
        }

        var res = false
        axios.get('/wp-json/wp/v2/search?'+query)
          .then(function (response) {
              if ( response.status === 200 ) {
                res = response.data
                if (res !== false) {
                  history.push({
                    pathname: '/resultater',
                    state: { res: res }
                  })
                }
              }
          })
          .catch(err => console.log(err))
      } else {
        if (inputValue.length > 0) {

          var query = 'text='+inputValue

          var res = false
          axios.get('/wp-json/wp/v2/search?'+query)
            .then(function (response) {
                if ( response.status === 200 ) {
                  res = response.data
                  if (res !== false) {
                    history.push({
                      pathname: '/resultater',
                      state: { res: res }
                    })
                  }
                }
            })
            .catch(err => console.log(err))
        }
      }
      closeModal()
    }

    function addToFilter(e) {
      var type = e.target.dataset.taxtype
      var slug = e.target.dataset.taxslug

      if (type === 'category') {
        var currentlySelectedCats = selectedCats
        var catsArr = []
        if (selectedCats.includes(slug)) {
          var index = selectedCats.indexOf(slug)
          currentlySelectedCats.splice(index, 1);
          catsArr.concat(currentlySelectedCats)
        } else {
          catsArr.push(slug)
          catsArr.concat(currentlySelectedCats)
        }
        var totalSelected = selectedTopics.length
        totalSelected += currentlySelectedCats.concat(catsArr).length
        setSelectedCats(currentlySelectedCats.concat(catsArr))
      } else {
        var currentlySelectedTopics = selectedTopics
        var topicsArr = []
        if (selectedTopics.includes(slug)) {
          var index = selectedTopics.indexOf(slug)
          currentlySelectedTopics.splice(index, 1);
          topicsArr.concat(currentlySelectedTopics)
        } else {
          topicsArr.push(slug)
          topicsArr.concat(currentlySelectedTopics)
        }
        var totalSelected = selectedCats.length
        totalSelected += currentlySelectedTopics.concat(topicsArr).length
        setSelectedTopics(currentlySelectedTopics.concat(topicsArr))
      }
      animateFilter(totalSelected)
    }

    function animateFilter(totalSelected) {
      var selectedCount = totalSelected;
  		setTimeout(function() {
  			if(selectedCount >= 1){
  				setHasSelections(true);
  				var category_height = document.getElementById('selected-buttons-list').clientHeight;
  				if(category_height >= 35){
  				  var full_height = category_height - 35;
  				  setTimeout(function() {
  				  setFilterWaveHeight(419+full_height+'px');
  				  }, 1);
  				}
  			} else{
  				setHasSelections(false);
  				setFilterWaveHeight(false);
  			}
  		}, 1);

    }
    if (location.state !== undefined && location.state.hideFilter !== undefined) {
      return ''
    }
    if (location.pathname.includes('/artikel/')) {
      return ''
    }

    return (
      <>
        <button style={{display:'none',opacity:0,transition: 'all 0.3s ease-in-out'}} onClick={openModal} className="filder-button">
          <FilterWave />
          <div className="filter-content">
            <FilterIcon />
            <span>filtre</span>
          </div>
        </button>
        <div className="after-filter-button"></div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          closeTimeoutMS={200}
        >
          <section className="filter-block background-dark">
            <button onClick={closeModal} className={`filter-close-btn ${hasSelections ? 'inverted' : ''}`}>
              <FilterCloseIcon />
            </button>

            <div id="selected-filter" style={{height: filterWaveHeight}} className={`background-white category-top selected-filter ${hasSelections ? 'active' : ''}`}>
              <div style={{height: filterWaveHeight}} className="top-content">
                <form className="search">
                  <input value={ inputValue } onChange={handleInputChange } placeholder="Hvad søger du?" type="text" />
                  <button onClick={doFilter}><SearchIcon /></button>
                </form>
		            <h2 className="color-dark search-title">valgte</h2>
                <div className="categories">

                  <div className="buttons" id="selected-buttons-list">
                    {categories !== false && categories.map((category, i) => {
                      return (
                        <button key={i} onClick={addToFilter}  data-taxtype={'category'} data-taxslug={category.slug} style={{backgroundColor:category.color}} className={`color-white ${selectedCats.includes(category.slug) ? 'selected' : ''}`}><CheckIcon />{category.name}</button>
                      )
                    })}
                    {topics !== false && topics.map((topic, i) => {
                      return (
                        <button key={i} onClick={addToFilter} data-taxtype={'topic'} data-taxslug={topic.slug} className={`background-dark color-white ${selectedTopics.includes(topic.slug)  ? 'selected' : ''}`}><CheckIcon />{topic.name}</button>
                      )
                    })}
                  </div>
                </div>
              </div>
              <ResultsWave />
            </div>

            {false &&
              <form className="search">
                <input placeholder="Hvad søger du?" type="text"/>
                <button onClick={addToFilter}><SearchIcon /></button>
              </form>
            }
      			<div className={`buttons-container ${hasSelections ? 'active' : ''}`}>
      				<div className="categories">
      					<h2 className="color-white">Kategorier</h2>
      					<div className="buttons">
      					{categories !== false && categories.map((category, i) => {
      					  return (
      						  <button key={i} onClick={addToFilter} data-taxtype={'category'} data-taxslug={category.slug} style={{backgroundColor:category.color}} className={`color-white ${selectedCats.includes(category.slug) ? 'hidden' : ''}`}>{category.name}</button>
      					  )
      					})}
      					</div>
      				</div>

      				<div className="themes">
      					<h2 className="color-white">Temaer</h2>
      					<div className="buttons">
      					{topics !== false && topics.map((topic, i) => {
      					  return (
      						<button key={i} onClick={addToFilter} data-taxtype={'topic'} data-taxslug={topic.slug} className={`background-white color-dark ${selectedTopics.includes(topic.slug)  ? 'hidden' : ''}`}>{topic.name}</button>
      					  )
      					})}
      					</div>
      				</div>
      			</div>

          	<button onClick={doFilter} className={`filder-button cls-btn ${hasSelections ? 'selected' : ''}`}>
              <FilterWave />
          		<div className="filter-content">
                <SearchIcon />
                <span className="color-dark">søg nu</span>
          			<span className="color-dark selected-count">{selectedTopics.length + selectedCats.length} valgt</span>
          		</div>
          	</button>
            <div className="after-filter-button"></div>

          </section>
        </Modal>
      </>
    )

}

export default Filter
