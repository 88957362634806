import React, { Component, Fragment, useState } from 'react'
import { ReactComponent as MenuSvg } from '../assets/svg/menu.svg';
import { ReactComponent as Lock } from '../assets/svg/lock.svg';
import { ReactComponent as Edit } from '../assets/svg/edit.svg';
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as Youtube } from '../assets/svg/youtube.svg';
import { ReactComponent as Facebook } from '../assets/svg/facebook.svg';
import { ReactComponent as Instagram } from '../assets/svg/instagram.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import { ReactComponent as FilterCloseIcon } from '../assets/svg/filter-close.svg';
import { ReactComponent as CheckIcon } from '../assets/svg/check-icon.svg';
import Modal from 'react-modal';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { wp } from '../index.js'
import WPAPI from 'wpapi';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Parse from 'html-react-parser';
// import MenuLogIn from './MenuLogIn';
// import MenuLoggedIn from './MenuLoggedIn';

Modal.setAppElement('#root')

export default class Menu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      navMenu: false,
      socialMedia: false,
      modalIsOpen: false,
      setIsOpen: false,
      login: localStorage.getItem('login') ? localStorage.getItem('login') : '',
      setLogin: false,
      setUser: false,
      password: false,
      username: false,
      email: false,
      error: false,
      forgottenPassword: false,
      resetEmailSent: false,
      mode: 'out-in',
      selectedFile: null,
      logoColor: false,
      logoColor: false,
      customStyles: {
        content : {
          height                : '100%',
          width                 : '100%',
          padding               : 0,
          border                : 'none',
          position: 'relative',
          transform: 'none',
          inset: 'unset',
          bottom: 0,
          'overflowY': 'scroll',
          marginRight: 0,
          top: '0',
          left: '0',
        },
        overlay: {
          zIndex                : '1000000',
          border                : 'none',
          bottom: 0,
          top: 0,
          left: 0,
          height: '100vh',
        },
      }
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.Logout = this.Logout.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.Edit = this.Edit.bind(this);
    this.UploadPhoto = this.UploadPhoto.bind(this);
    this.fileInput = React.createRef()
  }

  openModal() {
    this.setState({
      setIsOpen:true
    })
  }

  closeModal(){
    this.setState({setIsOpen:false})
  }

  componentDidMount() {
    const _this = this;

    wp.menuResource = wp.registerRoute( 'wp/v2', 'menu' );
    wp.menuResource()
      .then(
        function(res) {
          _this.setState({
            navMenu: res.menu,
            socialMedia: res.social,
            logoColor: res.logoColor,
            logoBackgroundColor: res.logoBackgroundColor
          })
          _this.props.setLogoColor( res.logoColor );
          _this.props.setLogoBackgroundColor( res.logoBackgroundColor );
          _this.props.setLogoTextColor( res.logoTextColor );

        }
      )
      .catch(err => console.log(err));

    if (this.state.login.length > 0) {
      this.getCurrentUser();
    }
  }

  componentDidUpdate() {
    if (this.state.login.length > 0 && Object.keys(this.state.user).length === 0) {
      this.getCurrentUser();
    }
  }

  getCurrentUser() {

      const token = this.state.login;
      const _this = this;

      wp.setHeaders( 'Authorization', 'Bearer '+token );

      wp.users().me().
      then(res => {
        _this.props.setUser( res );
        _this.setState( {user: res} );
      })
      .catch(function (error) {
          _this.Logout(false);
      })

  }

  Logout(e) {
    if (e !== false) {
      e.preventDefault()
    }
    this.props.setLoggedOut(true);
    setTimeout(() => {
      localStorage.removeItem('login');
      this.props.setLogin('');
      this.setState( {login: ''} );
    }, 200);
  }

  Edit(e) {
    e.preventDefault()
    this.fileInput.current.click()
  }

  UploadPhoto(e) {
    e.preventDefault()

    const file = e.target.files[0];
    const mimeType = file.type;
    const fileName = file.name
    const idxDot = fileName.lastIndexOf(".") + 1
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (!(extFile=="jpg" || extFile=="jpeg" || extFile=="png")){
      alert("Kun jpg/jpeg og png-filer er tilladt" );
      return
    }

    if ((file.size / 1024 / 1024) > 1) {
      alert("Filstørrelsen må ikke overstige mere end 1 MB" );
      return
    }

    const dataArray = new FormData();
    dataArray.append("uploadFile", e.target.files[0]);
    dataArray.append("userId", this.state.user.id);
    const _this = this;
    const token = this.state.login;

    axios
    .post("/wp-json/wp/v2/change-photo", dataArray, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${this.props.token}`
      }
    })
    .then(
      function(res) {
        _this.getCurrentUser();
      }
    )
    .catch(err => console.log(err));
  }

  doLogin() {
    const _this = this;

    axios({
     method: 'post',
     // url: "https://stuncode.lt/nk/wp/wp-json/jwt-auth/v1/token/",
     url: "https://backend.mit-nektar.dk/wp-json/jwt-auth/v1/token/",
     data: {
        username: this.state.username,
        password: this.state.password
      },
     config: {
         headers: {
           'Access-Control-Allow-Origin': '*',
         }
     }
    })
    .then(function (response) {
        if ( response.status === 200 ) {
            const data = response.data;
            _this.setState({error:false})
            setTimeout(() => {
              localStorage.setItem( 'login', data.token );
              _this.props.setLogin( data.token );
              _this.setState( {login: data.token} );
            }, 200);
        }
    })
    .catch(function (error) {
        function strip_html_tags(str) {
            if ((str===null) || (str===''))
                return false;
            else
            str = str.toString();
            return str.replace(/<[^>]*>/g, '');
        }
        _this.setState({error:true})
        console.log(strip_html_tags( error ))
        alert( 'Login mislykkedes. Tjek dit brugernavn og din adgangskode.' );
    });

  }

  forgotPassword(e) {
    e.preventDefault()
    this.setState({
      forgottenPassword: !this.state.forgottenPassword
    })
  }

  resetPassword() {
    const _this = this;

    axios({
      method: 'post',
      url: '/wp-json/wp/v2/reset-pass',
      data: {
        email: this.state.email
      }
    })
    .then(function(res) {
      if (res.status !== 200 || res.data == false) {
        _this.setState({error:true})

      } else {
        _this.setState({error:false})
        _this.setState({resetEmailSent:true})
      }
    })
    .catch(err => console.log(err));
  }

  handleEmail( email ) {
    this.setState({email})
  }

  handleUsername( username ) {
    this.setState({username})
  }

  handlePassword( password ) {
    this.setState({password})
  }

  render() {
    const {modalIsOpen, setIsOpen, customStyles, navMenu, socialMedia, error, forgottenPassword, mode, resetEmailSent, login, setLogin, loggedOut, setLoggedOut, selectedFile } = this.state;
    const {user, setUser} = this.props;
    return (
      <>
        <button onClick={this.openModal} style={{display:'none',opacity:0,transition: 'all 0.3s ease-in-out'}} className="header-menu background-white">
          <MenuSvg />
        </button>
        {setIsOpen &&
          <button  onClick={this.closeModal} className="header-menu header-menu-close background-dark">
            <FilterCloseIcon />
          </button>
        }
        <Modal
          isOpen={setIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          closeTimeoutMS={200}
        >
          <div className="login-form">
            <div className="background-green category-top">
              <div className="top-content">
              <SwitchTransition mode={mode}>
                <CSSTransition
                  key={login}
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  classNames="content"
                >
                  <>
                    {!login &&
                      <>
                        <div className="lock-svg">
                          <Lock />
                        </div>
                        <h3 className="color-white">log ind på nektar</h3>
                        <p className="color-white">Det er kun medlemmer af de grønne pigespejdere som kan få et login til NEKTAR. Hvis du er medlem men mangler et link til at oprette en profil skal du kontakte din gruppeleder, ellers kan du logge ind her under.</p>
                        <SwitchTransition mode={mode}>
                            <CSSTransition
                              key={forgottenPassword}
                              addEndListener={(node, done) => {
                                node.addEventListener("transitionend", done, false);
                              }}
                              classNames="content"
                            >
                              {!forgottenPassword
                                ?
                                  <div className="login-form-inputs">
                                    <input type="text" name="username" id="username"
                                      onChange={(e) => this.handleUsername( e.target.value )}
                                      className={(error ? 'color-white error' : 'color-white')}
                                      type="text"
                                      placeholder="Medlemsnummer" />
                                    <input name="password" id="password"
                                      onChange={(e) => this.handlePassword( e.target.value )}
                                      className={(error ? 'color-white error' : 'color-white')}
                                      type="password"
                                      placeholder="Password" />
                                    <a href="/" onClick={this.forgotPassword} className="forgot-password color-dark">Har du glemt dit password?</a>
                                    <button onClick={this.doLogin} className="background-dark color-white">Log ind<ArrowRight /></button>
                                  </div>
                                :
                                  <div className="login-form-inputs">
                                    <input type="text"
                                      className={(error ? 'color-white error' : 'color-white')}
                                      type="text"
                                      style={{visibility:'hidden'}}
                                      placeholder="E-mail" />
                                    <input type="text" name="email" id="email"
                                      onChange={(e) => this.handleEmail( e.target.value )}
                                      className={(error ? 'color-white error' : 'color-white')}
                                      type="text"
                                      placeholder="E-mail" />
                                    <a href="/" onClick={this.forgotPassword} className="forgot-password color-dark">Log ind</a>
                                    <button onClick={this.resetPassword} disabled={resetEmailSent} className="background-dark color-white reset-btn">
                                      {resetEmailSent &&
                                        <CheckIcon />
                                      }
                                      {!resetEmailSent &&
                                        <>
                                          Nulstille kodeord <ArrowRight />
                                        </>
                                      }
                                    </button>
                                  </div>
                              }
                            </CSSTransition>
                          </SwitchTransition>
                        </>
                      }
                      {login &&
                        <>
                          <div className="profile-image">
                            {user.acf !== undefined && user.acf.the_real_profile_picture !== undefined && user.acf.the_real_profile_picture.sizes &&
                              <img src={user.acf.the_real_profile_picture.sizes.medium} />
                            }
                            <form className="edit-profile">
                              <input
                                type="file"
                                ref={this.fileInput}
                                onChange={this.UploadPhoto}
                                accept="image/*"
                              />

                            </form>
                            <a href="/" onClick={this.Edit} className="edit-profile background-dark-green">
                              <Edit />
                            </a>
                            <a onClick={this.Logout} href="/" className="edit-profile logout"><Lock /></a>
                          </div>
                          <h3 className="color-white name-title">{user !== {} && user.name}</h3>
                          {user.acf !== undefined && user.acf.email !== undefined &&
                            <p className="profile-email color-white">{user.acf.email}</p>
                          }
                          <div className="profile-links">
                            <Link
                              onClick={this.closeModal}
                              to={{
                                pathname: '/mit-gemte-indhold',
                              }}
                            >
                                mit gemte indhold
                            </Link>
                            <Link
                              onClick={this.closeModal}
                              to={{
                                pathname: '/billedgalleri',
                              }}
                            >
                                Billedgalleri
                            </Link>
                            <Link
                              onClick={this.closeModal}
                              to={{
                                pathname: '/debat',
                              }}
                            >
                                Debat - sig det højt!
                            </Link>
                          </div>
                        </>
                      }
                    </>
                  </CSSTransition>
              </SwitchTransition>

          		</div>
              <SearchResultsWave />
            </div>
            <ul className="menu-block">
              {navMenu !== false && navMenu !== undefined && navMenu.map((navMenuItem, i) => {
                return (
                  <li key={i}>
                    <Link
                      className="color-dark"
                      onClick={this.closeModal}
                      to={{
                        pathname: navMenuItem.url,
                      }}
                    >
                        {navMenuItem.title}
                    </Link>
                  </li>
                )
               })}
            </ul>
            <div className="social-media">
              <a href={socialMedia.youtube} target="_blank"><Youtube /></a>
              <a href={socialMedia.facebook} target="_blank"><Facebook /></a>
              <a href={socialMedia.instagram} target="_blank"><Instagram /></a>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

function InlineCss(props) {
  return (
    Parse("<style>"+props.selector+" {"+props.property+": "+props.color+"!important;}</style>")
  )
}
