import React, { Component, Fragment } from 'react'
import Parse from 'html-react-parser';
import PostListSlider from '../components/PostListSlider';
import { ReactComponent as CheckIcon } from '../assets/svg/check-icon.svg';
import { ReactComponent as PercentIcon } from '../assets/svg/percent.svg';
import { ReactComponent as PlayPause } from '../assets/svg/playpause.svg';
import { ReactComponent as PlayPauseQuiz } from '../assets/svg/playpause-quiz.svg';
import { ReactComponent as Play } from '../assets/svg/play-icon.svg';
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as PostTextWave } from '../assets/svg/post-text-wave.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { CSSTransition, SwitchTransition } from 'react-transition-group';


class Quiz extends Component {
  state = {
    quizStarted: false,
    quizFinished: false,
    currentQuizQuestion: 0,
    totalCorrectAnswers: 0,
    correctAnswer: false,
    wrongAnswer: false,
    selectedAnswer: false,
    totalPoints: 0,
    quizId: 0,
  }

  constructor(props) {
    super(props);
    this.startQuiz = this.startQuiz.bind(this);
    this.setQuizQuestion = this.setQuizQuestion.bind(this);
    this.selectAnswer = this.selectAnswer.bind(this);
  }

  componentDidMount() {
    if (this.props.history.location.state) {
      this.setState({
        quizId: this.props.history.location.state.id,
      })
    }
  }

  componentDidUpdate() {
    if (this.props.history.location.state) {
      if (this.props.history.location.state.id !== this.state.quizId) {
        this.setState({
          quizStarted: false,
          quizFinished: false,
          currentQuizQuestion: 0,
          totalCorrectAnswers: 0,
          correctAnswer: false,
          wrongAnswer: false,
          selectedAnswer: false,
          totalPoints: 0,
          quizId: this.props.history.location.state.id,
        })
      }
    }

  }

  startQuiz() {
    this.setState({
      quizStarted: !this.state.quizStarted,
    })
  }

  setQuizQuestion() {
    if (this.props.stateParent.post.acf.quiz_builder.length !== this.state.currentQuizQuestion + 1) {
      this.setState({
        currentQuizQuestion: this.state.currentQuizQuestion + 1,
        selectedAnswer: false,
        wrongAnswer: false,
        correctAnswer: false,
      })
    } else {
      this.setState({
        quizFinished: true,
      })
    }
  }

  selectAnswer(e, index) {
    var currentQuestion = this.state.currentQuizQuestion
    var selectedAnswer = index

    if (this.state.selectedAnswer !== false) {
      return
    }
    this.setState({
      selectedAnswer: selectedAnswer
    })

    if (this.props.stateParent.post.acf.quiz_type === 'no_points') {
      if (this.props.stateParent.post.acf.quiz_builder.[currentQuestion].answer_options.[selectedAnswer].correct_answer === true) {
        this.setState({
          totalCorrectAnswers: this.state.totalCorrectAnswers + 1,
          correctAnswer: index
        })
      } else {
        this.setState({
          wrongAnswer: index
        })
      }
      if (this.props.stateParent.post.acf.use_explanations === false) {
        // var _this = this
        // setTimeout(function(){ _this.setQuizQuestion() },500);
      }
    } else {
      this.setState({
        totalPoints: parseInt(this.state.totalPoints) + parseInt(this.props.stateParent.post.acf.quiz_builder.[currentQuestion].answer_options.[selectedAnswer].points),
        wrongAnswer: false,
        correctAnswer: index,
      })
    }

  }

  render() {
    const {post, isLoaded, title, featuredImg, authors, caption, date, textInClrBlack, textInClrWhite, img1, img2, images, postType, subtitle, audiofile, playing, videofile, episode, played, duration, videoOrImage, topVideo } = this.props.stateParent;
    const {postLikes, postShares, postSaves, shareOpen} = this.props.stateParent;
    const {likedThisPost, savedThisPost} = this.props.stateParent;

    return (
      <div className={`inner-container ${postType}`}>
        <div className="container">
          <section style={{backgroundColor:post.category_color}} className={"single-post pop-up"}>
            <div className="background-cover-block">
              {videoOrImage == 'image' &&
                <img src={featuredImg} />
              }
              {videoOrImage == 'video' &&
                <div className="video-cont" dangerouslySetInnerHTML={{ __html: `
                  <video muted autoPlay playsinline loop>
                    <source src="${topVideo.url}" type="${topVideo.mime_type}"/>
                    Your browser does not support the video tag.
                  </video>
                `}}>
                </div>
              }
              {postType === 'video' && videofile !== false &&
                <>
                  <CSSTransition
                    in={!playing}
                    timeout={100}
                    classNames="content"
                    unmountOnExit
                  >
                    <div className="audio-controls">
                      <button onClick={this.play} className="play">
                        <PlayPause />
                        <CSSTransition
                          in={!playing}
                          timeout={50}
                          classNames="playing"
                          unmountOnExit
                        >
                          <span className="play-active"><Play /></span>
                        </CSSTransition>
                      </button>
                    </div>
                  </CSSTransition>
                </>
              }
            </div>
            <div style={{backgroundColor:post.category_color}} className="text-block first-post-block">
              <SvgColor color={post.category_color} selector={'div.post-top-wave > svg path'} />
              <div className="post-top-wave">
                  <>
                    <PostTextWave />
                    <CSSTransition in={this.state.quizStarted && !this.state.quizFinished} unmountOnExit  timeout={200} classNames="alert">
                      <div className="current-question">
                        <PlayPauseQuiz />
                        <div>
                          {this.state.currentQuizQuestion+1} / {post.acf.quiz_builder.length}
                        </div>
                      </div>
                    </CSSTransition>
                  </>
                </div>
                <div className="transform-top">
                <>
                  {!this.state.quizStarted &&
                    <div className="quiz-intro">
                      <h1 className="text-block-title">{title}</h1>
                      {this.props.stateParent.quizIntro}
                      <button onClick={this.startQuiz} className="background-dark color-white form-submit-button">
                        {this.props.stateParent.quizButtonText}
                        <ArrowRight />
                      </button>
                    </div>
                  }

                  <CSSTransition in={this.state.quizStarted} unmountOnExit  timeout={200} classNames="alert">
                    <SwitchTransition mode={'out-in'}>
                      <CSSTransition
                        key={this.state.quizFinished}
                        addEndListener={(node, done) => {
                          node.addEventListener("transitionend", done, false);
                        }}
                        classNames="alert"
                      >
                        <>
                          {!this.state.quizFinished &&
                            <div className="quiz-questions">
                              <SwitchTransition mode={'out-in'}>
                                <CSSTransition
                                  key={this.state.currentQuizQuestion}
                                  addEndListener={(node, done) => {
                                    node.addEventListener("transitionend", done, false);
                                  }}
                                  classNames="alert"
                                >
                                    <div className="options">
                                      <h2>{post.acf.quiz_builder.[this.state.currentQuizQuestion].question}</h2>
                                      {post.acf.quiz_builder.[this.state.currentQuizQuestion].answer_options.length > 0 && post.acf.quiz_builder.[this.state.currentQuizQuestion].answer_options.map((answer, iA) => {
                                        return(
                                          <div
                                            onClick={(e) => this.selectAnswer(e, iA)}
                                            className={`
                                              option
                                              ${post.acf.quiz_type === 'no_points' && post.acf.quiz_builder.[this.state.currentQuizQuestion].answer_options.[iA].correct_answer === true && this.state.selectedAnswer !== false ? 'correct' : ''}
                                              ${post.acf.quiz_type === 'points' && this.state.correctAnswer === iA ? 'correct' : ''}
                                              ${this.state.wrongAnswer === iA ? 'wrong' : ''}
                                            `}
                                            key={iA}
                                          >
                                            <div><PlayPause/><span>{iA+1}</span></div>{answer.answer_text}
                                          </div>
                                        )
                                      })}
                                      <CSSTransition in={this.state.selectedAnswer !== false} unmountOnExit  timeout={200} classNames="alert">
                                        <>
                                          {post.acf.use_explanations === true &&
                                            <div className={`answer-explanation ${this.state.wrongAnswer !== false ? 'wrong' : ''}`}>
                                              {this.state.selectedAnswer !== false && post.acf.quiz_builder.[this.state.currentQuizQuestion].answer_options.[this.state.selectedAnswer].correct_answer === true ?
                                                <h2><CheckIcon />Dit svar er rigtigt</h2>
                                              :
                                                <h2><PercentIcon />Dit svar er forkert</h2>
                                              }
                                              {Parse(post.acf.quiz_builder.[this.state.currentQuizQuestion].explanation)}
                                              <button className="background-white color-dark form-submit-button next" onClick={this.setQuizQuestion}>
                                                {this.state.currentQuizQuestion+1 < post.acf.quiz_builder.length ?
                                                  'Næste spørgsmål'
                                                 :
                                                  'Se resultater'
                                                }
                                                <ArrowRight />
                                              </button>
                                            </div>
                                          }
                                          {post.acf.use_explanations === false &&
                                            <button className="background-white color-dark form-submit-button next" onClick={this.setQuizQuestion}>
                                              {this.state.currentQuizQuestion+1 < post.acf.quiz_builder.length ?
                                                'Næste spørgsmål'
                                               :
                                                'Se resultater'
                                              }
                                              <ArrowRight />
                                            </button>
                                          }
                                        </>
                                      </CSSTransition>
                                    </div>
                                  </CSSTransition>
                                </SwitchTransition>
                              </div>
                            }
                            {this.state.quizFinished && post.acf.quiz_type === 'no_points' &&
                              <CSSTransition in={this.state.quizFinished} unmountOnExit  timeout={200} classNames="alert">
                                <div className="quiz-explanation">
                                  <h2>Du svarede rigtigt på</h2>
                                  <h1>{this.state.totalCorrectAnswers} / {post.acf.quiz_builder.length}</h1>
                                  <h2>spørgsmål, godt klaret!</h2>
                                </div>
                              </CSSTransition>
                            }
                            {this.state.quizFinished && post.acf.quiz_type === 'points' &&
                              <CSSTransition in={this.state.quizFinished} unmountOnExit  timeout={200} classNames="alert">
                                <div className="quiz-explanation">
                                  <h2>{this.state.totalPoints} POINT</h2>
                                  {Parse(post.acf.end_text)}
                                </div>
                              </CSSTransition>
                            }
                          </>

                      </CSSTransition>
                    </SwitchTransition>


                  </CSSTransition>
                </>
              </div>
            </div>
          </section>
          <section className="custom-title outside-icon">
            Prøv flere <span style={{color:post.category_color}}>quizzer og test</span>
            <div className="custom-title-icon">
              <ArrowDown />
            </div>
          </section>
          {post.similarPosts &&
            <PostListSlider postType={postType} slider={post.similarPosts} />
          }

        </div>
      </div>
    )
  }
}

export default Quiz

function SvgColor(props) {
  return (
    Parse("<style>"+props.selector+" {fill: "+props.color+"!important;}</style>")
  )
}
