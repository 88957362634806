import React, { Component, Fragment } from 'react'
import { ReactComponent as PodcastWave } from '../assets/svg/podcast-wave.svg';
import { ReactComponent as MessagesIcon } from '../assets/svg/messeges-icon.svg';
import { ReactComponent as BravoIcon } from '../assets/svg/bravo-icon.svg';
import { ReactComponent as CommentIcon } from '../assets/svg/comment.svg';
import { ReactComponent as CommentIconSend } from '../assets/svg/arrow-right.svg';
import { ReactComponent as CheckIcon } from '../assets/svg/check-icon.svg';
import { ReactComponent as FilterCloseIcon } from '../assets/svg/filter-close.svg';
import Parse from 'html-react-parser';
import Modal from 'react-modal';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import {Config} from '../index.js';
// import TimeAgo from 'javascript-time-ago'
import axios from 'axios';
import TimeAgo from 'javascript-time-ago'
import Moment from 'moment';
import ReactTimeAgo from 'react-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)

export default class DebatModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      setIsOpen: false,
      typingComment: false,
      comment: '',
      comments: false,
      isLoaded: false,
      commentSent: false,
      customStyles: {
        content : {
          bottom: 0,
          left: 0,
          height                : '50vh',
          width                 : '100%',
          padding               : 0,
          border                : 'none',
          background: 'transparent',
        },
        overlay: {
          zIndex                : '9',
          border                : 'none',
          position :'fixed',
          background: 'transparent'
        },
      },
      customLabels: {
        second: {
          past: {
            one: "{0} s siden",
            other: "{0} s siden"
          },
        },
        minute: {
          past: {
            one: "{0} min siden",
            other: "{0} min siden"
          },
        },
        hour: {
          past: {
            one: "{0} t siden",
            other: "{0} t siden"
          },
        },
        day: {
          past: {
            one: "{0} dag siden",
            other: "{0} dag siden"
          },
        },
      },
      round: [
          {
            // "second" labels are used for formatting the output.
            formatAs: 'second'
          },
          {
            // This step is effective starting from 59.5 seconds.
            minTime: 60,
            // "minute" labels are used for formatting the output.
            formatAs: 'minute'
          },
          {
            // This step is effective starting from 59.5 minutes.
            minTime: 60 * 60,
            // "hour" labels are used for formatting the output.
            formatAs: 'hour'
          },
          {
            // This step is effective starting from 59.5 minutes.
            minTime: 60 * 60 * 24,
            // "hour" labels are used for formatting the output.
            formatAs: 'day'
          },
      ]


    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showCommentInput = this.showCommentInput.bind(this);
    this.postComment = this.postComment.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.fileInput = React.createRef()
  }

  componentDidMount() {
    this.getComments()
  }
  componentDidUpdate() {
    if (this.state.comments == false) {
      this.getComments()
    }
    if (this.state.commentSent == true) {

      this.getComments()
      this.setState({
        commentSent: false,
      })
    }
  }

  getComments() {
    var _this = this

    axios.get("/wp-json/wp/v2/get-debate-comments?post="+this.props.post.id, {
    }).then(
      function(res) {
        if (res.data == false) {
          _this.setState({
            isLoaded: true,
            comments:{},
          })
        } else {
          _this.setState({
            isLoaded: true,
            comments:res.data,
          })
        }
      }
    ).catch(err => console.log(err));
  }

  postComment(e) {
    e.preventDefault()

    if (!this.state.comment || this.state.comment.length < 2) {
      return
    }

    const _this = this;
    const dataArray = new FormData();
    dataArray.append("comment", this.state.comment);
    dataArray.append("userId", this.props.user.id);
    dataArray.append("postId", this.props.post.id);

    axios.post("/wp-json/wp/v2/post-comment", dataArray, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${this.props.token}`
      }
    }).then(
      function(res) {
        _this.setState({
          commentSent:true,
          comment:''
        })
      }
    ).catch(err => console.log(err));

  }

  showCommentInput(e) {
    e.preventDefault()
    this.setState({
      typingComment:true
    })
  }

  handleComment(comment) {
    this.setState({comment})
  }

  openModal(e) {
    e.preventDefault()
    this.setState({
      setIsOpen:true
    })
  }

  closeModal(e){
    e.preventDefault()
    this.setState({setIsOpen:false})
  }

  render() {
    const {setIsOpen, customStyles, typingComment, comments, isLoaded, commentSent} = this.state;

    TimeAgo.addLabels('da', 'custom', this.state.customLabels)
    const timeAgo = new TimeAgo('da-DK')
    const customStyle = {
      steps: this.state.round,
      labels: 'custom'
    }

    if (isLoaded) {
      return (
        <>
          {Parse('<style>body:not(.loading) .filder-button{display:none!important}</style>')}
          <section className="single-post pop-up podcast-comments-container">
        		<a href="/" onClick={this.props.closeModal} className="post-exit-button"><FilterCloseIcon/></a>
        		<div className="background-cacoa category-top podcast-comments">
        			<div className="post-block top-content">
        				<div className="author-image">
                  {this.props.post.authorPic && this.props.post.authorPic.sizes &&
                    <img src={this.props.post.authorPic.sizes.thumbnail} />
                  }
        				</div>
        				<div className="post-content">
        					<div className="post-data color-dark">
        						<span className="upload-date"><DateFormatted string={timeAgo.format(Date.parse(this.props.post.date.replace(/-/g, '/').replace(/[a-z]+/gi, ' ')), customStyle)} date={this.props.post.date} /></span>
        						<span className="border">|</span>
        						<span className="post-author">{this.props.post.authorName}</span>
        					</div>
        					<h3 className="color-dark">{this.props.post.title}</h3>
        					<ul onClick={this.openModal} className="features-list">
        						<li className="messeges">
        							<MessagesIcon />
                      <span>{this.props.post.shares && this.props.post.shares.length > 0 ? this.props.post.shares.length : 0}</span>
        						</li>
        						<li className="bravo">
        							<BravoIcon />
                      <span>{this.props.post.likes && this.props.post.likes.length > 0 ? this.props.post.likes.length : 0}</span>
        						</li>
        					</ul>
        				</div>
        			</div>
        			<PodcastWave />
        		</div>
            {comments.length > 0 && comments.map((comment, i) => {

              if (comment.isPostAuthor) {
                return (
                  <div key={i} className="post-block author-comment">
                    <div className="author-image">
                      {this.props.post.authorPic && this.props.post.authorPic.sizes &&
                        <img src={this.props.post.authorPic.sizes.thumbnail} />
                      }
                    </div>
                    <div className="post-content background-cacoa">

                      <div className="post-data color-dark">
                        <span className="upload-date"><DateFormatted string={timeAgo.format(Date.parse(comment.date.replace(/-/g, '/').replace(/[a-z]+/gi, ' ')), customStyle)} date={comment.date} /></span>
                        <span className="border">|</span>
                        <span className="post-author">{comment.author}</span>
                      </div>
                      <div className="post-short-text color-dark">
                        <p className="color-dark">{Parse(comment.comment)}</p>
                      </div>
                    </div>
                  </div>
                )
              }
              return (
                <div key={i} className="post-block">
                  <div className="author-image">
                    {comment.authorPic && comment.authorPic.sizes &&
                      <img src={comment.authorPic.sizes.thumbnail} />
                    }
                  </div>
                  <div className="post-content background-cacoa">
                    <div className="post-data color-dark">
                      <span className="upload-date"><DateFormatted string={timeAgo.format(Date.parse(comment.date.replace(/-/g, '/').replace(/[a-z]+/gi, ' ')), customStyle)} date={comment.date} /></span>
                      <span className="border">|</span>
                      <span className="post-author">{comment.author}</span>
                    </div>
                    <div className="post-short-text color-dark">
                      <p className="color-dark">{Parse(comment.comment)}</p>
                    </div>
                  </div>
                </div>
              )
            })}

            {typingComment == false &&
              <a href="/" onClick={this.showCommentInput} className="button color-white background-dark write-comment"><CommentIcon />tilføj en kommentar</a>
            }
            <SwitchTransition mode={'out-in'}>
              <CSSTransition
                key={typingComment}
                addEndListener={(node, done) => {
                  node.addEventListener("transitionend", done, false);
                }}
                classNames="content"
              >
                <>
                  {typingComment &&
                    <form className="comment-form">
                      <input
                        type="text"
                        ref={this.commentInput}
                        onChange={(e) => this.handleComment( e.target.value )}
                        accept="image/*"
                        placeholder="Skriv en kommentar ..."
                        value={this.state.comment}
                        disabled={commentSent}
                      />
                      <button type="submit" onClick={this.postComment}>
                        {commentSent ?
                          <CheckIcon />
                        :
                          <CommentIconSend />
                        }
                      </button>
                    </form>
                  }
                </>
              </CSSTransition>
            </SwitchTransition>

        	</section>

          <Modal
            isOpen={setIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            closeTimeoutMS={200}
          >
            {Parse('<style>.podcast-comments-container{filter: blur(6px);-webkit-filter: blur(6px);-moz-filter: blur(6px);-o-filter: blur(6px);-ms-filter: blur(6px)}</style>')}
            <section className="members-list-container background-white">
            	<a href="/" onClick={this.closeModal} className="post-exit-button"><FilterCloseIcon /></a>
            		<ul>
                  {this.props.post.usersReacted && Object.keys(this.props.post.usersReacted).map((i) => {
                    return (
                      <li key={i}>
                        <div className="left-side">
                          <div className="member-image">
                            {this.props.post.usersReacted[i].authorPhoto.sizes &&
                              <img src={this.props.post.usersReacted[i].authorPhoto.sizes.thumbnail} />
                            }
                          </div>
                          <h3 className="color-dark member-name">{this.props.post.usersReacted[i].username}</h3>
                        </div>
                        <div className="right-side">
                          {this.props.post.usersReacted[i].shared &&
                            <button><MessagesIcon /></button>
                          }
                          {this.props.post.usersReacted[i].liked &&
                            <button><BravoIcon /></button>
                          }
                        </div>
                      </li>
                    )
                  })}
            		</ul>
            </section>
          </Modal>
        </>
      )
    }
    return ''
  }

}

function DateFormatted(props) {
  if (props.string.includes('dag siden') && props.string !== '1 dag siden') {
    return (
      Moment(props.date).format('DD.MM.YYYY')
    )
  }
  return (
    props.string
  )
}
