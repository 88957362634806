import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as TopicWave } from '../assets/svg/theme-slider-wave.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import { ReactComponent as Heart } from '../assets/svg/heart.svg';
import { ReactComponent as GlassesIcon } from '../assets/svg/glasses.svg';
import { ReactComponent as AudioIcon } from '../assets/svg/earphones.svg';
import { ReactComponent as VideoIcon } from '../assets/svg/video.svg';
import { ReactComponent as CheckIcon } from '../assets/svg/check-icon.svg';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import {Helmet} from "react-helmet";
import Parse from 'html-react-parser';

export class Page extends React.Component {
  state = {
    page: [],
    pagePath: false,
    isLoaded: false,
    error: false,
    submitDone: false,
  }

  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    this.getThisPage()
  }
  componentDidUpdate() {
    if (this.props.location.pathname != this.state.pagePath) {
      this.getThisPage()
    }
    if (this.props.loggedIn.length < 1 && this.state.contact_form_id == 402) {
      this.props.history.push('/')
    }
  }

  getThisPage() {
    wp.pageResource = wp.registerRoute( 'wp/v2', 'get_page/(?P<slug>)' );
    var slug = this.props.match.params.slug
    wp.pages().slug(slug).embed().get()
    .then(res => this.setState({
      pagePath: this.props.location.pathname,
      page: res[0],
      title: res[0].title.rendered,
      featuredImg: res[0].acf.main_image !== undefined ? res[0].acf.main_image.url : false,
      textInBackground: (res[0].acf.text_content !== undefined && res[0].acf.text_content.text_in_colored_background !== undefined) ? Parse(res[0].acf.text_content.text_in_colored_background) : false,
      textInWhite: (res[0].acf.text_content !== undefined && res[0].acf.text_content.text_in_white_background !== undefined) ? Parse(res[0].acf.text_content.text_in_white_background) : false,
      color: (res[0].acf.text_content !== undefined && res[0].acf.text_content.top_text_background_color !== undefined) ? res[0].acf.text_content.top_text_background_color : false,
      backgroundColor: res[0].acf.top_text_background_color ? res[0].acf.top_text_background_color : false,
      buttons: (res[0].acf.text_content !== undefined && res[0].acf.text_content.button !== undefined) ? res[0].acf.text_content.button : false,
      add_buttons: (res[0].acf.text_content !== undefined && res[0].acf.text_content.add_buttons !== undefined) ? res[0].acf.text_content.add_buttons : false,
      isLoaded: true,
      template: res[0].template,
      contact_form_id: res[0].contact_form_id ? res[0].contact_form_id : false,
      error: false,
      submitDone: false,
      videoOrImage: res[0].acf.video_or_featured_image !== undefined ? res[0].acf.video_or_featured_image : 'image',
      topVideo: res[0].acf.video_or_featured_image == 'video' ? res[0].acf.video : false,
      visiblePosts: [],
      ogTitle: res[0].acf.title_og,
      ogDescription: res[0].acf.description_og,
      ogImage: res[0].acf.image_og ? res[0].acf.image_og.sizes.large : false,
      ogImageWidth: res[0].acf.image_og ? res[0].acf.image_og.sizes['large-width'] : false,
      ogImageHeight: res[0].acf.image_og ? res[0].acf.image_og.sizes['large-height'] : false,
    }))
    .catch(err => console.log(err));

  }

  submitForm() {
    var form = document.querySelector('form')
    var data = new FormData(form);
    var formId = this.state.contact_form_id
    var _this = this
    if (this.props.loggedIn.length > 0) {
      data.append('userId', this.props.user.id)
    }
    if (this.props.loggedIn.length < 1 && this.state.contact_form_id == 402) {
      this.props.history.push('/')
    }

    axios({
      method: 'post',
      // url: '/wp-json/wp/v2/submit-form',
      url: '/wp-json/contact-form-7/v1/contact-forms/'+formId+'/feedback',
      data: data
    })
    .then(
      function(res) {
        if (res.data.status == "mail_sent") {
          _this.setState({error:false})
          _this.setState({submitDone:true})
        } else {
          _this.setState({error:res.data.message})
          _this.setState({submitDone:false})
        }
      }
    )
    .catch(
      function(res) {
        _this.setState({error:true})
        _this.setState({submitDone:false})
      }
    );
  }

  onChange(visible, id) {
    if (visible) {
      if (this.state.visiblePosts.includes(id) === false) {
        var currentlyVisiblePosts = this.state.visiblePosts
        currentlyVisiblePosts.push(id)
        this.setState({
          visiblePosts: currentlyVisiblePosts
        })
      }
    }
  }

  render() {
    const {isLoaded, template} = this.state;
    if (isLoaded) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);
      if (template === 'redaktionen-template.php') {
        const {page, title, featuredImg, textInBackground, textInWhite, color, buttons, add_buttons, backgroundColor, template, ogTitle, ogDescription, ogImage, ogImageWidth, ogImageHeight} = this.state;
        return (
          <EditorsPage
            page={page}
            title={title}
            featuredImg={featuredImg}
            textInBackground={textInBackground}
            textInWhite={textInWhite}
            color={backgroundColor}
            buttons={buttons}
            add_buttons={add_buttons}
            isLoaded={isLoaded}
            onChange={this.onChange}
            visiblePosts={this.state.visiblePosts}
            ogTitle={ogTitle}
            ogDescription={ogDescription}
            ogImage={ogImage}
            ogImage={ogImageWidth}
            ogImage={ogImageHeight}
          />
        );
      } else if (template === 'contacts-template.php') {
        const {page, title, featuredImg, textInBackground, textInWhite, color, buttons, add_buttons, backgroundColor, template, ogTitle, ogDescription, ogImage, ogImageWidth, ogImageHeight} = this.state;
        return (
          <ContactsPage
            page={page}
            title={title}
            color={backgroundColor}
            isLoaded={isLoaded}
            ogTitle={ogTitle}
            ogDescription={ogDescription}
            ogImage={ogImageWidth}
            ogImage={ogImageHeight}
          />
        );
      } else if (template === "contact-form-template.php") {
        const {page, title, featuredImg, textInBackground, textInWhite, color, buttons, add_buttons, backgroundColor, template, error, submitDone, ogTitle, ogDescription, ogImage, ogImageWidth, ogImageHeight} = this.state;
        if (this.props.loggedIn.length < 1 && this.state.contact_form_id == 402) {
          this.props.history.push('/')
        }
        return (
          <Fragment>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={ogTitle ? ogTitle : title} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={ogImage ? ogImage : featuredImg} />
                <meta property="og:description" content={ogDescription} />
            </Helmet>

            <div style={{backgroundColor:backgroundColor}} className="category-top sadan-form-top">
            	<div className="top-content color-white">
            		<h2 className="color-white">{title}</h2>
                {Parse(page.acf.text)}
            	</div>
              <SearchResultsWave />
            </div>

            <div className="margin-top-block">
            	<div className="custom-title for-publish-form">
            		<div className="custom-title-container">
                  {Parse(page.acf.contact_form_title)}
            			<div className="custom-title-icon">
            				<ArrowDown />
            			</div>
            		</div>
            	</div>
              {Parse(page.contact_form)}
              <button onClick={this.submitForm} disabled={submitDone} className="background-dark color-white form-submit-button">
                {submitDone &&
                  <CheckIcon />
                }
                {!submitDone &&
                  <>
                    Indsend<ArrowRight />
                  </>
                }
              </button>
              {error &&
                <div className="wpcf7-form error">
                  <p className="wpcf7-acceptance">
                    <label>{error}</label>
                  </p>
                </div>
              }
            </div>
          </Fragment>
        );
      } else {
        const {page, title, featuredImg, textInBackground, textInWhite, color, buttons, add_buttons, videoOrImage, topVideo, ogTitle, ogDescription, ogImage, ogImageWidth, ogImageHeight} = this.state;
        return (
          <SimplePage
            page={page}
            title={title}
            featuredImg={featuredImg}
            textInBackground={textInBackground}
            textInWhite={textInWhite}
            color={color}
            buttons={buttons}
            add_buttons={add_buttons}
            isLoaded={isLoaded}
            videoOrImage={videoOrImage}
            topVideo={topVideo}
            ogTitle={ogTitle}
            ogDescription={ogDescription}
            ogImage={ogImage}
            ogImage={ogImageWidth}
            ogImage={ogImageHeight}
          />
        );
      }
    }
    return ''
  }
}

export default Page

function Image(props) {
  const acf = props.acf;
  const img = props.image;
  try {
    if (props.acf.text_content.[img] !== false) {
      if (img === 'image_1') {
        return <img className="first-image" srcSet={`${props.acf.text_content.[img].sizes.medium} 300w, ${props.acf.text_content.[img].sizes.large} 768w, ${props.acf.text_content.[img].url} 1280w`} src={props.acf.text_content.[img].sizes.medium} />
      }
      if (img === 'image_2') {
        let imgClass = 'second-image'
        if (props.acf.text_content.image_1 === false) {
          let imgClass = 'first-image'
        }
        return <img className={imgClass} srcSet={`${props.acf.text_content.[img].sizes.medium} 300w, ${props.acf.text_content.[img].sizes.large} 768w, ${props.acf.text_content.[img].url} 1280w`} src={props.acf.text_content.[img].sizes.medium} />
      }
    }
  } catch (error) {
    console.log(error);
  }
  return ''
}

function SvgColor(props) {
  return (
    Parse("<style>"+props.selector+" {fill: "+props.color+"!important;}</style>")
  )
}

function InlineCss(props) {
  return (
    Parse("<style>"+props.selector+"{"+props.rule+": "+props.value+";}</style>")
  )
}

function SimplePage(props) {
  return (
    <>
      <Helmet>
          <title>{props.title}</title>
          <meta property="og:title" content={props.ogTitle ? props.ogTitle : props.title} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={props.ogImage ? props.ogImage : props.featuredImg} />
          <meta property="og:description" content={props.ogDescription} />
      </Helmet>
      <div className="inner-container">
        <section className="theme-single">
            {props.videoOrImage == 'image' &&
              <div className="background-cover-block">
                <img src={props.featuredImg} />
              </div>
            }
            {props.videoOrImage == 'video' &&
              <div className="background-cover-block" dangerouslySetInnerHTML={{ __html: `
                <video muted autoPlay playsinline loop>
                  <source src="${props.topVideo.url}" type="${props.topVideo.mime_type}"/>
                  Your browser does not support the video tag.
                </video>
              `}}>
              </div>
            }
          <InlineCss value={'linear-gradient(to bottom,'+props.color+' 14.1rem,transparent 20%)'} rule={'background'} selector={'.first-post-block:after'} />
          <div style={{backgroundColor:props.color}} className="text-block first-post-block">
            <div className="post-top-wave">
              <SvgColor color={props.color} selector={'.post-top-wave svg path'} />
              <TopicWave />
            </div>
            <div className="transform-top category-top">
              <div className="top-content color-white">
                <h1>{props.title}</h1>
                {props.textInBackground}
              </div>
            </div>

          </div>
          <div className="text-block-images" style={{background: 'linear-gradient(to bottom,'+props.color+' 17.4rem,#fff 20%)'}}>
            <Image image={'image_1'} acf={props.page.acf} />
            <Image image={'image_2'} acf={props.page.acf} />
          </div>
          <div className="text-block background-white">
            {props.textInWhite}
            {props.add_buttons !== false && props.buttons !== false && props.buttons.map((button, i) => {
              if (button.new_page === 'no') {
                return <a href={button.link} key={i} className="button margin-auto content-button background-dark color-white">{button.text}<ArrowRight /></a>
              } else {
                return <a href={button.link} key={i} target="_blank" className="button margin-auto content-button background-dark color-white">{button.text}<ArrowRight /></a>
              }
             })}
          </div>

        </section>
      </div>
    </>
  )
}

function EditorsPage(props, isVisible) {
  const text = props.page.acf.text !== undefined ? Parse(props.page.acf.text) : ''
  return (
    <>
      <Helmet>
          <title>{props.title}</title>
          <meta property="og:title" content={props.ogTitle ? props.ogTitle : props.title} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={props.ogImage ? props.ogImage : ''} />
          <meta property="og:description" content={props.ogDescription} />
      </Helmet>

      <div className="container">
        <div style={{backgroundColor:props.color}} className="category-top redaktionen-top">
        	<div className="top-content color-dark">
        		<h1 className="color-dark">{props.title}</h1>
        		<div className="subtitle">{props.page.acf.subheading} <span><span className="border">|</span><span>{props.page.editors.length}</span> frivillige</span></div>
            {text}
            <Link
              className="background-dark color-white button with-icon"
              to={{
                pathname: props.page.acf.button_link,
              }}
            >
              <Heart />{props.page.acf.button_text}
            </Link>
        	</div>
        	<SearchResultsWave />
        </div>

        <section className="post-list redaktionen-list-container">
        	<ul className="redaktionen-list">
          {props.page.editors.map((editor, i) => {
            return (
              <VisibilitySensor key={i} partialVisibility  onChange={isVisible => props.onChange(isVisible, editor.username)} >
                <li key={i} className={`${props.visiblePosts && props.visiblePosts.includes(editor.username) ? 'is-visible' : ''}`}>
                  <Link
                    className="theme-author"
                    to={{
                      pathname: '/redaktion/'+editor.username,
                    }}
                  >
                    <div className="profile-image">
                      {editor.photo !== null &&
                        <img src={editor.photo.thumbnail} />
                      }
                    </div>
                    <h3 className="color-dark name-title">{editor.display_name}</h3>
                    <p className="author-position color-dark">{editor.title}</p>
                    <ul className="author-posts">
                      <li><VideoIcon /><span>{editor.videos !== null ? editor.videos : 0}</span></li>
                      <li><AudioIcon /><span>{editor.podcasts !== null ? editor.podcasts : 0}</span></li>
                      <li><GlassesIcon /><span>{editor.texts !== null ? editor.texts : 0}</span></li>
                    </ul>
                  </Link>
                </li>
              </VisibilitySensor>
            )
          })}
        	</ul>
        </section>
      </div>
    </>
  )
}

function ContactsPage(props) {
  const text = props.page.acf.text !== undefined ? Parse(props.page.acf.text) : ''
  return (
    <>
      <Helmet>
          <title>{props.title}</title>
          <meta property="og:title" content={props.ogTitle ? props.ogTitle : props.title} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={props.ogImage ? props.ogImage : ''} />
          <meta property="og:description" content={props.ogDescription} />
      </Helmet>
      <div className="container">
        <div className="background-green category-top contacts-block">
        	<div className="top-content color-white">
            <h1 className="color-white">{props.title}</h1>
            {Parse(props.page.acf.top_text)}
        		<div className="contact-info">
              {Parse(props.page.acf.contacts)}
        		</div>
        	</div>
          <SearchResultsWave />
        </div>
        <section className="partners-links">
          {props.page.acf.bottom_links.map((bottomLink, i) => {
            return (
              <a target="_blank" href={bottomLink.link} className="button color-dark background-cacoa"><img src={bottomLink.image.sizes.thumbnail} />{bottomLink.text}</a>
            )
          })}
        </section>
      </div>
    </>
  )
}
