import React from 'react';
import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import { App } from './App.js';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as serviceWorker from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import WPAPI from 'wpapi';
import { BrowserRouter as Router } from 'react-router-dom'
import Parse from 'html-react-parser';
import { ReactComponent as Logo } from './assets/svg/logo-new.svg';
import { ReactComponent as LogoFull } from './assets/svg/logo-full-n.svg';
import SimpleReactLightbox from 'simple-react-lightbox'
import TimeAgo from 'javascript-time-ago'
import da from 'javascript-time-ago/locale/da'

TimeAgo.addDefaultLocale(da)

// export let wpUrl = 'https://stuncode.lt/nk/wp/wp-json';
export let wpUrl = 'https://backend.mit-nektar.dk/wp-json';
export const Config = {
  apiUrl: wpUrl,
  AUTH_TOKEN: 'auth-token',
  USERNAME: 'username',
};
export const wp = new WPAPI({ endpoint: Config.apiUrl });

// axios.defaults.baseURL = 'https://stuncode.lt/nk/wp/';
axios.defaults.baseURL = 'https://backend.mit-nektar.dk/';

const rootElement = document.getElementById("root");

axios({
  method: 'get',
  // url: '/wp-json/wp/v2/submit-form',
  url: '/wp-json/wp/v2/get-branding',
})
.then(function(res) {
  // ReactDOM.render(<><a class="n-logo" href="/"><Logo /></a><a class="n-logo-full" href="/"><LogoFull /></a></>, document.getElementById('outerlogo'));
  ReactDOM.render(<a href="/"><Logo /></a>, document.getElementById('outerlogo'));

  if (res.status === 200) {
    ReactDOM.render(
      <InlineCss color={res.data.logoColor} backgroundColor={res.data.logoBackgroundColor} />,
      document.getElementById('styles')
    );
  }

  const rootElement = document.getElementById("root");
  let renderMethod = render;
  if (rootElement.hasChildNodes()) {
    renderMethod = hydrate
  }
  renderMethod(
    <React.StrictMode>
      <Router>
        <SimpleReactLightbox>
          <>
            <App />
          </>
        </SimpleReactLightbox>
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
})
.catch(err => console.log(err));

function InlineCss(props) {
  return (
    Parse(
      "<style>body.loading{background-color: "+props.backgroundColor+"!important;}a.logo-homepage > svg path.st2, a.logo > svg path,#outerlogo .st1{fill:"+props.color+"}a.logo-homepage > svg path.st0, .logo svg.simple-logo path.st0{fill:"+props.backgroundColor+"}#outerlogo .st0{fill:none}</style>"
    )
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log());
