import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import { useHistory } from 'react-router-dom'
import '../styles/post-list.css'
import '../styles/homepage.css'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as HeroWave } from '../assets/svg/top-banner-wave.svg';
import { ReactComponent as GlassesIcon } from '../assets/svg/glasses.svg';
import { ReactComponent as AudioIcon } from '../assets/svg/earphones.svg';
import { ReactComponent as VideoIcon } from '../assets/svg/video.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import PostListSlider from '../components/PostListSlider';
import CategoriesSlider from '../components/CategoriesSlider';
import TopicsSlider from '../components/TopicsSlider';
import { CSSTransition } from 'react-transition-group';
import SearchResultLlist from '../components/SearchResultLlist';
import {Helmet} from "react-helmet";

class CategoryPage extends Component {
  state = {
    category: {},
    isLoaded: false,
    catPath: false,
    selectedType: false,
    showAll: true,
    showPodcast: false,
    showText: false,
    showVideo: false,
  }

  constructor(props) {
    super(props);
    this.switchType = this.switchType.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname != this.state.catPath) {
      window.scrollTo(0, 0)
      this.getCategory()
      if (this.props.match.params.slug !== prevProps.match.params.slug) {
        this.switchType(false, this.props.location.state.selectedType)
      }
    }
  }

  componentDidMount() {
    document.body.classList.add('loading');
    this.getCategory()
  }

  switchType(e, type) {
    if (e !== false) {
      e.preventDefault()
    }

    if (this.state.selectedType === type) {
      this.setState({
        selectedType: false,
        showAll: true,
        showPodcast: false,
        showText: false,
        showVideo: false,
      })
    } else {
      if (type === 'video') {
        this.setState({
          showAll: false,
          showPodcast: false,
          showText: false,
          showVideo: true,
          selectedType: type,
        })
      }
      if (type === 'podcast') {
        this.setState({
          showAll: false,
          showPodcast: true,
          showText: false,
          showVideo: false,
          selectedType: type,
        })
      }
      if (type === 'text') {
        this.setState({
          showAll: false,
          showPodcast: false,
          showText: true,
          showVideo: false,
          selectedType: type,
        })
      }
    }
  }

  getCategory() {
    wp.getCategoryResource = wp.registerRoute( 'wp/v2', 'get-taxonomy/(?P<slug>)' );
    var catSlug = this.props.match.params.slug
    wp.getCategoryResource().slug(catSlug).get()
      .then(
        res => this.setState({
          category: res,
          isLoaded: true,
          catPath: this.props.location.pathname
        })
    ).catch(err => console.log(err));

    if (this.props.location.state !== undefined && this.props.location.state.selectedType !== undefined) {
      // this.setState({
      //   selectedType: this.props.location.state.selectedType,
      //   showAll: this.props.location.state.showAll,
      //   showPodcast: this.props.location.state.showPodcast,
      //   showText: this.props.location.state.showText,
      //   showVideo: this.props.location.state.showVideo,
      // })
      // this.switchType(false, this.props.location.state.selectedType)
    }
  }

  render() {
    const {category, isLoaded, selectedType, showAll, showPodcast, showVideo, showText } = this.state;
    if (isLoaded) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);

      if (
        category.categoryPodcasts.length === 0 &&
        category.categoryVideos.length === 0 &&
        category.categoryTexts.length === 0
      ) {
        this.props.history.push('/')
      }
      return (
        <div className="inner-container page">
          <Helmet>
              <title>{category.title}</title>
          </Helmet>
          <div style={{backgroundColor:category.category_color}} className="category-top">
          	<div className="top-content">
          		<h1 className="color-white">{category.title}</h1>
          		<span>Kategori</span>
          		<div className="theme-post-types">
                <a href="/" onClick={(e) => this.switchType(e, 'video')} data-type="video" className={`button background-dark color-white ${showVideo === true ? 'active' : ''} ${category.categoryVideos.length < 1 ? 'disabled' : ''}`}><VideoIcon />{category.categoryVideos.length} videoer</a>
                <a href="/" onClick={(e) => this.switchType(e, 'podcast')} data-type="podcast" className={`button background-dark color-white ${showPodcast === true ? 'active' : ''} ${category.categoryPodcasts.length < 1 ? 'disabled' : ''}`}><AudioIcon />{category.categoryPodcasts.length} podcast</a>
                <a href="/" onClick={(e) => this.switchType(e, 'text')} data-type="text" className={`button background-dark color-white ${showText === true ? 'active' : ''} ${category.categoryTexts.length < 1 ? 'disabled' : ''}`}><GlassesIcon />{category.categoryTexts.length} artikler</a>
          		</div>
          		<p className="color-white">{category.description}</p>
          	</div>
          	<SearchResultsWave />
          </div>
          <CSSTransition
            in={showAll}
            timeout={100}
            classNames="content"
            unmountOnExit
          >
            <div className="category-block-content">
              {category.sliders.map((slider, i) => {
                if (slider.type !== 'topics') {
                  return (
                    <PostListSlider key={i} slider={slider} />
                  )
                } else {
                  return (
                    <Fragment key={i}>
                      <section className="custom-title">
                        Udforsk <span className="color-light-purple">nektars</span> temaer
                        <ArrowDown />
                      </section>
                      <TopicsSlider topics={slider} />
                    </Fragment>
                  )
                }
               })}
               {category.sliders.length < 1 &&
                 <SearchResultLlist posts={category.categoryAllPosts} />
               }
              <section className="custom-title outside-icon">
                Udforsk <span style={{color:category.category_color}}>nektars</span> andre kategorier
                <div className="custom-title-icon">
                  <ArrowDown />
                </div>
              </section>
              <ul className="category-list">
                <CategoriesSlider categories={category.otherCats} />
              </ul>
            </div>
          </CSSTransition>
          <CSSTransition
            in={showPodcast}
            timeout={100}
            classNames="content"
            unmountOnExit
          >
            <div className="category-block-content">
              <SearchResultLlist posts={category.categoryPodcasts} />
              <PostListSlider slider={category.podcastSeries} />
              <section className="custom-title outside-icon">
                Udforsk <span style={{color:category.category_color}}>nektars</span> andre kategorier
                <div className="custom-title-icon">
                  <ArrowDown />
                </div>
              </section>
              <ul className="category-list">
                <CategoriesSlider categories={category.otherCats} />
              </ul>
            </div>
          </CSSTransition>
          <CSSTransition
            in={showVideo}
            timeout={100}
            classNames="content"
            unmountOnExit
          >
            <div className="category-block-content">
              <SearchResultLlist posts={category.categoryVideos} />
              <PostListSlider slider={category.videoSeries} />
              <section className="custom-title outside-icon">
                Udforsk <span style={{color:category.category_color}}>nektars</span> andre kategorier
                <div className="custom-title-icon">
                  <ArrowDown />
                </div>
              </section>
              <ul className="category-list">
                <CategoriesSlider categories={category.otherCats} />
              </ul>
            </div>
          </CSSTransition>
          <CSSTransition
            in={showText}
            timeout={100}
            classNames="content"
            unmountOnExit
          >
            <div className="category-block-content">
              <SearchResultLlist posts={category.categoryTexts} />
              <PostListSlider slider={category.textSeries} />
              <section className="custom-title outside-icon">
                Udforsk <span style={{color:category.category_color}}>nektars</span> andre kategorier
                <div className="custom-title-icon">
                  <ArrowDown />
                </div>
              </section>
              <ul className="category-list">
                <CategoriesSlider categories={category.otherCats} />
              </ul>
            </div>
          </CSSTransition>
        </div>
      )
    }
    return ''
  }

}

export default CategoryPage
