import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { wp } from '../index.js'
import axios from 'axios'
import Parse from 'html-react-parser';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import { ReactComponent as GlassesIcon } from '../assets/svg/glasses.svg';
import { ReactComponent as AudioIcon } from '../assets/svg/earphones.svg';
import { ReactComponent as VideoIcon } from '../assets/svg/video.svg';
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import SearchResultLlist from '../components/SearchResultLlist';
import {Helmet} from "react-helmet";

class UserPage extends Component {
  state = {
    isLoaded: false,
    username: '',
    user: []
  }

  constructor(props) {
    super(props);
    this.getBack = this.getBack.bind(this);
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    this.getWpUser()
  }

  getWpUser() {
    var username = this.props.match.params.username
    wp.getUserResource = wp.registerRoute( 'wp/v2', 'get-user/(?P<slug>)' );

    wp.getUserResource().slug(username).get()
    .then(
      res => this.setState({
        user: res,
        isLoaded: true,
        username: this.props.match.params.username
      })
    ).catch(err => console.log(err));
  }

  getBack(e) {
    e.preventDefault()
    this.props.history.goBack()
  }

  render() {
    const {isLoaded, user} = this.state;

    if (isLoaded) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);
      return (
        <div className="inner-container">
          <Helmet>
              <title>{user.display_name}</title>
          </Helmet>
          <section className="single-post pop-up redaktionen-single">
            {false &&
              <a href="/" onClick={this.getBack} className="post-exit-button"><ArrowRight/></a>
            }
            <div style={{backgroundColor: user.color}} className="category-top">
              <SearchResultsWave />
            </div>
            <div className="theme-author">
              <div className="profile-image">
                {user.photo && <img src={user.photo.medium} />}
              </div>
              <h3 className="color-dark name-title">{user.display_name}</h3>
              <p className="author-position color-dark">{user.title}</p>
              <ul className="author-posts">
                <li><VideoIcon /><span>{user.videos !== null ? user.videos : 0}</span></li>
                <li><AudioIcon /><span>{user.podcasts !== null ? user.podcasts : 0}</span></li>
                <li><GlassesIcon /><span>{user.texts !== null ? user.texts : 0}</span></li>
              </ul>
              <p className="short-description color-dark">{user.description}</p>
            </div>
            {user.posts &&
              <>
                <section className="custom-title outside-icon">
                  gå på opdagelse i <span style={{color: user.color}}>{user.genitiv ? user.genitiv : user.display_name}</span> arbejde
                  <div className="custom-title-icon">
                    <ArrowDown />
                  </div>
                </section>
                <SearchResultLlist posts={user.posts} />
              </>
            }
          </section>
        </div>
      )
    }

    return ''
  }
}

export default UserPage
