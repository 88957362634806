import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { wp } from '../index.js'
import '../styles/post-list.css'
import '../styles/homepage.css'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow-down.svg';
import { ReactComponent as HeroWave } from '../assets/svg/top-banner-wave.svg';
import { ReactComponent as GlassesIcon } from '../assets/svg/glasses.svg';
import { ReactComponent as AudioIcon } from '../assets/svg/earphones.svg';
import { ReactComponent as VideoIcon } from '../assets/svg/video.svg';
import { ReactComponent as SearchResultsWave } from '../assets/svg/search-result-wave.svg';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import SearchResultLlist from '../components/SearchResultLlist';
import Parse from 'html-react-parser';
import { Link } from 'react-router-dom';

class SavedContent extends Component {
  state = {
    saved: {},
    isLoaded: false,
    // catPath: false,
    selectedType: false,
    loggedIn: false,
    user: {},
    showAll: true,
    showPodcast: false,
    showText: false,
    showVideo: false,
    showingMore: false
  }

  constructor(props) {
    super(props);
    this.switchType = this.switchType.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (Object.keys(this.props.user).length > 0 && Object.keys(this.state.user).length == 0) {
      this.setState({
        loggedIn: this.props.loggedIn,
        user: this.props.user,
      })
      this.getSavedContent()
    }
  }

  componentDidMount() {
    document.querySelector('body').classList.add('loading')
    this.setState({
      loggedIn: this.props.loggedIn,
      user: this.props.user,
    })

    this.getSavedContent()
  }

  switchType(e) {
    e.preventDefault()
    this.setState({
      selectedType: false,
      showAll: false,
      showPodcast: false,
      showText: false,
      showVideo: false,
    })
    if (this.state.selectedType === e.target.dataset.type) {
      this.setState({
        selectedType: false,
        showAll: true,
        showPodcast: false,
        showText: false,
        showVideo: false,
      })
    } else {
      if (e.target.dataset.type === 'video') {
        this.setState({
          showAll: false,
          showPodcast: false,
          showText: false,
          showVideo: true,
          selectedType: e.target.dataset.type,
        })
      }
      if (e.target.dataset.type === 'podcast') {
        this.setState({
          showAll: false,
          showPodcast: true,
          showText: false,
          showVideo: false,
          selectedType: e.target.dataset.type,
        })
      }
      if (e.target.dataset.type === 'text') {
        this.setState({
          showAll: false,
          showPodcast: false,
          showText: true,
          showVideo: false,
          selectedType: e.target.dataset.type,
        })
      }
    }
  }

  getSavedContent() {
    if (Object.keys(this.props.user).length > 0) {
      const userId = this.props.user.id
      const _this = this;
      const dataArray = new FormData();
      dataArray.append("userId", userId);
      axios
      .post("/wp-json/wp/v2/get-saved-content", dataArray, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(
        function(res) {
          if (res.status == 200) {
            _this.setState({
              saved: res.data,
              isLoaded: true,
            })
          }
        }
      )
      .catch(err => console.log(err));
    }
  }

  render() {

    const {saved, isLoaded, selectedType, showAll, showPodcast, showVideo, showText } = this.state;
    if (isLoaded) {
      setTimeout(function(){ document.body.classList.remove('loading'); },500);

      if (
        saved === false ||
        (
          saved.podcasts.length === 0 &&
          saved.videos.length === 0 &&
          saved.texts.length === 0
        )
      ) {
        // this.props.history.push('/')
      }
      return (
        <div className="inner-container">
          <div className="background-cacoa category-top">
          	<div className="samling-result top-content">
          		<h1 className="color-dark">Mit gemte indhold</h1>
          		<div className="theme-post-types">
          			<a
                  href="/"
                  onClick={this.switchType}
                  data-type="video"
                  className={'button background-dark color-white ' + (saved.videos.length === 0 ? ' disabled' : '') + (showVideo ? ' active' : '')}
                >
                  <VideoIcon/>{saved.videos.length} videoer
                </a>
          			<a
                  href="/"
                  onClick={this.switchType}
                  data-type="podcast"
                  className={'button background-dark color-white ' + (saved.podcasts.length === 0 ? ' disabled' : '') + (showPodcast ? ' active' : '')}
                >
                  <AudioIcon/>{saved.podcasts.length} podcast
                </a>
          			<a
                  href="/"
                  onClick={this.switchType}
                  data-type="text"
                  className={'button background-dark color-white ' + (saved.texts.length === 0 ? ' disabled' : '') + (showText ? ' active' : '')}
                >
                  <GlassesIcon/>{saved.texts.length} artikler
                </a>
          		</div>
          	</div>
          	<SearchResultsWave />
          </div>


          <div className="samling-margin-top">
            <CSSTransition
              in={showAll}
              timeout={100}
              classNames="samling"
              unmountOnExit
            >
              <SearchResultLlist posts={saved.allPosts} />
            </CSSTransition>
            <CSSTransition
              in={showPodcast}
              timeout={100}
              classNames="samling"
              unmountOnExit
            >
              <SearchResultLlist posts={saved.podcasts} />
            </CSSTransition>
            <CSSTransition
              in={showVideo}
              timeout={100}
              classNames="samling"
              unmountOnExit
            >
              <SearchResultLlist posts={saved.videos} />
            </CSSTransition>
            <CSSTransition
              in={showText}
              timeout={100}
              classNames="samling"
              unmountOnExit
            >
              <SearchResultLlist posts={saved.texts} />
            </CSSTransition>
          </div>
        </div>
      )
    }
    return ''
  }

}

export default SavedContent
